import React, { useEffect, useState } from 'react';
import { Autocomplete, Button, Fade, TablePagination, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import CachedRoundedIcon from '@mui/icons-material/CachedRounded';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import Grow from '@mui/material/Grow';
import { entityListSimple } from '../../interfaces/generic-entity.interface';
import { VirtualizedListBox } from '../../components/misc/VirtualizedListBox';

interface entityListSearchProps {
    type:
        | 'sectors'
        | 'institutions'
        | 'DPOHs'
        | 'MPs'
        | 'committees'
        | 'subjects'
        | 'issues'
        | 'organizations'
        | 'inactive organizations'
        | 'firms'
        | 'consultants';
    list: any[];
    subList?: entityListSimple[];
    onClick?: (...args: any[]) => void;
    disableLinks?: boolean;
    embedded?: boolean;
    endIcon?: any;
    parentCode?: string;
    parentName?: string;
}

const renderOptions = (props: React.HTMLAttributes<HTMLLIElement>, option: any) => {
    return (
        <Fade in={true} key={option.code}>
            <li {...props}>{option.title}</li>
        </Fade>
    );
};

const EntityListSearch = ({
    type,
    list,
    subList,
    onClick = () => {},
    disableLinks = false,
    embedded = false,
    endIcon = null,
    parentCode = '',
    parentName = '',
}: entityListSearchProps) => {
    const [searchValue, setSearchValue] = useState<any | null>(null);
    const [results, setResults] = useState<any[] | null>(null);

    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);

    const [searchSublist, setSearchSublist] = useState<boolean>(false);

    // Manage current desktop state. True if viewport width is greater than the mobile breakpoint.
    const mobile_breakpoint = 1024;
    const [isDesktop, setDesktop] = useState<boolean>(window.innerWidth > mobile_breakpoint);

    // Listen for viewport resize. Update media if necessary
    useEffect(() => {
        window.addEventListener('resize', updateMedia);
        return () => window.removeEventListener('resize', updateMedia);
    });
    // Report new viewport width as determined by useEffect.
    const updateMedia = () => {
        setDesktop(window.innerWidth > mobile_breakpoint);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChange = (newValue: any) => {
        setSearchValue(newValue);
        handleSearch(newValue);
    };

    const handleSearch = (newValue: any) => {
        if (newValue === null) {
            setResults([]);
        } else if (searchSublist) {
            const matchingSector = list.find((sector) => sector.code === newValue.parent);
            setSearchValue(newValue.title);
            if (matchingSector) {
                setResults([matchingSector]);
            } else {
                setResults(matchingSector);
            }
            onClick(matchingSector);
        } else if (list.map((item: any) => item.title).includes(newValue.title)) {
            setSearchValue(newValue.title);
            setResults([newValue]);
            onClick(newValue);
        } else {
            setSearchValue(newValue);
            setResults(
                list.filter((item: any) =>
                    item.title.toLowerCase().includes(newValue.toLowerCase())
                )
            );
        }
    };

    const handleClear = () => {
        setResults(null);
        setSearchValue('');
    };

    return (
        <div className="grid grid-cols-12">
            <div
                className={`col-span-12 grid grid-cols-12 ${
                    !embedded && 'bg-white p-4 rounded-md shadow-md'
                }`}
            >
                <div className="lg:col-span-4 col-span-12 flex flex-col gap-2 justify-between items-center">
                    {type === 'sectors' && subList && (
                        <Button
                            size="small"
                            variant="contained"
                            sx={{ marginBottom: '5px' }}
                            disableElevation
                            onClick={() => setSearchSublist(!searchSublist)}
                            startIcon={<CachedRoundedIcon />}
                        >
                            {searchSublist
                                ? 'Search Sectors by Name'
                                : 'Search Sectors by Organization'}
                        </Button>
                    )}
                    <div className="flex flex-col gap-2 w-full">
                        <Autocomplete
                            freeSolo
                            fullWidth
                            disableClearable
                            disableListWrap
                            size="small"
                            value={searchValue}
                            onChange={(e, newValue: any) => handleChange(newValue)}
                            options={searchSublist ? subList || [] : list}
                            getOptionLabel={(option: any) => option.title ?? ''}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={
                                        searchSublist
                                            ? `Search by Organization`
                                            : type === 'sectors'
                                            ? `Search by Sector`
                                            : `Search ${type}`
                                    }
                                />
                            )}
                            ListboxComponent={
                                isDesktop && searchSublist ? VirtualizedListBox : undefined
                            }
                            renderOption={renderOptions}
                        />
                        <Button
                            fullWidth
                            color="error"
                            disabled={results === null}
                            onClick={handleClear}
                            startIcon={<NotInterestedIcon />}
                            variant="contained"
                        >
                            clear results
                        </Button>
                    </div>
                    <div className="text-slate-400 italic my-6">Search available {type}</div>
                    <TablePagination
                        labelRowsPerPage="#"
                        labelDisplayedRows={({ from, to, count }) =>
                            `${from}-${to} of ${count !== -1 ? count : `more than ${to}`}`
                        }
                        rowsPerPageOptions={[5, 10, 15]}
                        component="div"
                        count={results ? results.length : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>

                <div className="lg:col-span-8 col-span-12 flex flex-row">
                    <div className="hidden lg:flex h-full w-0.5 border border-r-slate-200 mx-8"></div>
                    <div className="text-slate-400 text-sm w-full">
                        <div className="pb-2">
                            {results
                                ? results.length +
                                  ' result(s) for ' +
                                  type +
                                  ' containing: "' +
                                  searchValue +
                                  '"'
                                : 'Search results will appear here...'}
                        </div>
                        {results ? (
                            results
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((entity: any, idx: number) => (
                                    <Grow key={idx} in={true}>
                                        <div className="border-t border-t-slate-100 text-slate-500 font-medium text-md">
                                            {disableLinks ? (
                                                <button
                                                    className="px-2 w-full py-2 flex flex-row items-center justify-between text-left transition ease-in-out hover:bg-blue-50"
                                                    onClick={() => onClick(entity)}
                                                >
                                                    {entity.title}
                                                    {endIcon}
                                                </button>
                                            ) : (
                                                <div className="w-full py-2 flex flex-col items-start">
                                                    <Link
                                                        className="pl-2 text-blue-400 hover:underline"
                                                        to={
                                                            disableLinks
                                                                ? '#'
                                                                : type === 'organizations'
                                                                ? `/sectors/${parentCode}/organizations/${encodeURIComponent(
                                                                      entity.title
                                                                  )}?sector=${parentName}`
                                                                : type === 'inactive organizations'
                                                                ? `/sectors/${parentCode}/organizations/inactive/${
                                                                      entity.title
                                                                  }?sector=${encodeURIComponent(
                                                                      parentName
                                                                  )}`
                                                                : type === 'consultants'
                                                                ? `/firms/${encodeURIComponent(
                                                                      parentCode
                                                                  )}/consultants/${
                                                                      entity.title
                                                                  }?firm=${encodeURIComponent(
                                                                      parentName
                                                                  )}`
                                                                : type === 'DPOHs'
                                                                ? `/institutions/${encodeURIComponent(
                                                                      parentCode
                                                                  )}/dpoh/${
                                                                      entity.title
                                                                  }?institution=${encodeURIComponent(
                                                                      parentName
                                                                  )}${
                                                                      entity.title2 &&
                                                                      entity.title2 === 'Minister'
                                                                          ? '&includeHOC=true'
                                                                          : ''
                                                                  }${
                                                                      parentCode === '103'
                                                                          ? '&includeMinisterPosition=true'
                                                                          : ''
                                                                  }`
                                                                : searchSublist
                                                                ? `/${type}/${
                                                                      entity.code
                                                                  }?name=${encodeURIComponent(
                                                                      entity.title
                                                                  )}`
                                                                : `/${type}/${
                                                                      entity.code
                                                                  }?name=${encodeURIComponent(
                                                                      entity.title
                                                                  )}`
                                                        }
                                                    >
                                                        {entity.title}
                                                        {entity.title2 &&
                                                            ' - ' + entity.title2}{' '}
                                                        {type === 'committees' &&
                                                            '(' + entity.code + ')'}
                                                    </Link>
                                                    {type === 'sectors' && (
                                                        <div className="flex lg:flex-row flex-col items-start lg:gap-2">
                                                            <Button
                                                                component={Link}
                                                                to={`/sectors/${
                                                                    entity.code
                                                                }/organizations?sector=${encodeURIComponent(
                                                                    entity.title
                                                                )}`}
                                                                sx={{ px: 1, py: 0 }}
                                                                size="small"
                                                                color="success"
                                                            >
                                                                Organizations
                                                            </Button>
                                                            <Button
                                                                component={Link}
                                                                to={`/sectors/${
                                                                    entity.code
                                                                }/organizations/inactive?sector=${encodeURIComponent(
                                                                    entity.title
                                                                )}`}
                                                                sx={{ px: 1, py: 0 }}
                                                                size="small"
                                                                color="success"
                                                            >
                                                                Inactive Organizations
                                                            </Button>
                                                        </div>
                                                    )}
                                                    {type === 'firms' && (
                                                        <Button
                                                            component={Link}
                                                            to={`/firms/${
                                                                entity.code
                                                            }/consultants?firm=${encodeURIComponent(
                                                                entity.title
                                                            )}`}
                                                            sx={{
                                                                px: 1,
                                                                py: 0,
                                                            }}
                                                            size="small"
                                                            color="info"
                                                        >
                                                            Consultants
                                                        </Button>
                                                    )}
                                                    {type === 'institutions' && (
                                                        <Button
                                                            component={Link}
                                                            to={`/institutions/${
                                                                entity.code
                                                            }/dpoh?institution=${encodeURIComponent(
                                                                entity.title
                                                            )}`}
                                                            sx={{
                                                                px: 1,
                                                                py: 0,
                                                            }}
                                                            size="small"
                                                            color="info"
                                                        >
                                                            DPOH List
                                                        </Button>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </Grow>
                                ))
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EntityListSearch;
