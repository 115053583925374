import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../store';
import { setCurrentUrl } from '../../helpers/sessionStoreUtil';

export enum ProductType {
    PROFESSIONAL = 'PROFESSIONAL',
    CORPORATE = 'CORPORATE',
    ENTERPRISE = 'ENTERPRISE',
    TRIAL = 'TRIAL',
}

export interface Permissions {
    productType: ProductType;
    maxSessions: number;
    sectors: number;
    institutions: number;
    committees: number;
    issues: number;
    subjects: boolean;
    newsLetters: number;
    customTracker: boolean;
    inHouseDashboard: boolean;
    maxQuery: number;
    maxRowsDownload: number;
    newsletterArchive: boolean;
    tfIdf: boolean;
}

export interface InHouse {
    isFirm: boolean;
    inHouseName: string;
    sectorName?: string;
    sectorCode?: number;
}

interface UserState {
    email: string | null;
    email_verified: boolean;
    firstName?: string;
    lastName?: string;
    company?: string;
    job_title?: string;
    industry?: string;
    phone?: string;
    gracePeriod?: string;
    parentUser?: boolean;
    additionalUsers?: number;
    permissions?: Permissions;
    inHouse?: InHouse;
    licenseAccepted?: boolean;
    childUserList?: ChildUser[];
    lastChildModified?: number;
    queryCount?: number;
    rowCount?: number;
    loading: boolean;
    actionLoading: boolean;
    errored: boolean;
    tooManySessions: boolean;
}

export interface EmailTrialRequest {
    email: string;
    name: string;
    company: string;
    committeeMeetings: string[];
    stakeholderSectors: string[];
    keywordAlerts: string[];
    grInsider: boolean;
    questionPeriod: boolean;
    customIssue: boolean;
}

const initialState: UserState = {
    email: null,
    email_verified: false,
    loading: false,
    actionLoading: false,
    errored: false,
    tooManySessions: false,
};

interface UserData {
    firstName: string;
    lastName: string;
    company: string;
    job_title: string;
    industry: string;
    phone: string;
    email?: string;
}

export interface ChildUser {
    email: string;
    firstName: string;
    lastName: string;
}

const baseURI = process.env.REACT_APP_API_BASE_URL;

export const fetchActiveUser = createAsyncThunk('user/fetch', async () => {
    const userInfo = await axios.get(`${baseURI}/user/info`);
    return userInfo.data;
});

export const resendValidationEmail = createAsyncThunk('user/revalidate', async () => {
    await axios.post(`${baseURI}/user/validationEmail`);
});

export const logIn = createAsyncThunk('user/logIn', async () => {
    setCurrentUrl();
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/login`);

    window.location.replace(response.data.url);
});

export const signUp = createAsyncThunk('user/signUp', async () => {
    setCurrentUrl();
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/signup`);

    window.location.replace(response.data.url);
});

export const authenticateUser = createAsyncThunk(
    'user/authenticate',
    async ({ authCode, state }: { authCode: string; state: string }, thunk) => {
        await axios.post(`${baseURI}/redirect`, {
            authCode,
            state,
        });

        await thunk.dispatch(fetchActiveUser()).unwrap();
    }
);

export const createUser = createAsyncThunk('user/create', async (userData: UserData) => {
    await axios.post(`${baseURI}/user/create`, {
        userData,
    });
});

export const redirectToPayment = createAsyncThunk('user/payment', async (productId: string) => {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/subscribe`, {
        productId,
    });

    window.location.replace(response.data.url);
});

export const logout = createAsyncThunk('user/logout', async () => {
    await axios.post(`${baseURI}/logout`);
});

export const applyForTrial = createAsyncThunk(
    'user/applyForTrial',
    async (userData: UserData, thunk) => {
        const createResponse = await axios.post(`${baseURI}/user/create`, {
            userData,
        });

        if (createResponse.status === 201) {
            const trialResponse = await axios.post(
                'https://liq-functions.azurewebsites.net/api/trial_start_email?code=APKOL8t9FVvSDJMzvMyRcqBtssn-wV8uOZAPgypwPqeuAzFujpmJ0Q==',
                { email: userData.email, trial_type: 'website' },
                { withCredentials: false }
            );

            if (trialResponse.status === 201) {
                await thunk.dispatch(fetchActiveUser()).unwrap();
            }
        }
    }
);

export const applyForEmailTrial = createAsyncThunk(
    'user/applyForEmailTrial',
    async (trialRequest: EmailTrialRequest) => {
        const createResponse = await axios.post(`${baseURI}/user/trial`, {
            trialRequest,
        });

        if (createResponse.status === 201) {
            await axios.post(
                'https://liq-functions.azurewebsites.net/api/trial_start_email?code=APKOL8t9FVvSDJMzvMyRcqBtssn-wV8uOZAPgypwPqeuAzFujpmJ0Q==',
                { email: trialRequest.email, trial_type: 'email' },
                { withCredentials: false }
            );
        }
    }
);

export const acceptLicense = createAsyncThunk('user/license', async () => {
    await axios.post(`${baseURI}/user/license`);
});

export const fetchChildUsers = createAsyncThunk('user/children', async () => {
    const response = await axios.get(`${baseURI}/user/children`);

    return response.data;
});

export const updateChildUsers = createAsyncThunk(
    'user/updateChildren',
    async (newChildren: ChildUser[]) => {
        const response = await axios.post(`${baseURI}/user/children`, {
            newChildren: newChildren.filter(
                (child) => child.email !== '' && child.firstName !== '' && child.lastName !== ''
            ), // filter out null and emptyString
        });

        return response.data;
    }
);

const userSlice = createSlice({
    name: 'userReducer',
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        iterateQueryCount(state) {
            state.queryCount = state.queryCount! - 1;
        },
        reduceRemainingRows(state, action: PayloadAction<number>) {
            state.rowCount = state.rowCount! - action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchActiveUser.pending, (state) => {
                state.errored = false;
                state.tooManySessions = false;
                state.loading = true;
            })
            .addCase(fetchActiveUser.fulfilled, (state, action) => {
                state.loading = false;
                state.email = action.payload.email;
                state.email_verified = action.payload.email_verified;
                state.firstName = action.payload.firstName;
                state.lastName = action.payload.lastName;
                state.company = action.payload.company;
                state.job_title = action.payload.job_title;
                state.industry = action.payload.industry;
                state.phone = action.payload.phone;
                state.gracePeriod = action.payload.gracePeriod;
                state.inHouse = action.payload.inHouse;
                state.licenseAccepted = action.payload.accepted_license;
                state.parentUser = action.payload.parentUser;
                state.additionalUsers = action.payload.additionalUsers;
                state.lastChildModified = action.payload.lastChildModified;
                state.queryCount = action.payload.queryCount;
                state.rowCount = action.payload.rowCount;
                state.permissions = action.payload.permissions;
            })
            .addCase(fetchActiveUser.rejected, (state, error) => {
                if (error.error.message === 'Request failed with status code 403') {
                    state.tooManySessions = true;
                } else {
                    state.tooManySessions = false;
                }
                state.errored = true;
                state.loading = false;
                state.email = null;
            })
            .addCase(authenticateUser.pending, (state) => {
                state.tooManySessions = false;
                state.errored = false;
                state.loading = true;
            })
            .addCase(authenticateUser.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(authenticateUser.rejected, (state) => {
                state.errored = true;
                state.loading = false;
                state.email = null;
            })
            .addCase(logout.pending, (state) => {
                state.errored = false;
                state.loading = true;
            })
            .addCase(logout.fulfilled, (state) => {
                state.email = null;
                state.email_verified = false;
                state.firstName = undefined;
                state.lastName = undefined;
                state.company = undefined;
                state.job_title = undefined;
                state.industry = undefined;
                state.phone = undefined;
                state.licenseAccepted = undefined;
                state.gracePeriod = undefined;
                state.inHouse = undefined;
                state.parentUser = undefined;
                state.additionalUsers = undefined;
                state.permissions = undefined;
                state.childUserList = undefined;
                state.lastChildModified = undefined;
                state.queryCount = undefined;
                state.loading = false;
            })
            .addCase(logout.rejected, (state) => {
                state.loading = false;
            })
            .addCase(redirectToPayment.pending, (state) => {
                state.errored = false;
                state.actionLoading = true;
            })
            .addCase(redirectToPayment.fulfilled, (state) => {
                state.errored = false;
                state.actionLoading = false;
            })
            .addCase(redirectToPayment.rejected, (state) => {
                state.errored = true;
                state.actionLoading = false;
            })
            .addCase(applyForTrial.pending, (state) => {
                state.errored = false;
                state.actionLoading = true;
            })
            .addCase(applyForTrial.fulfilled, (state) => {
                state.errored = false;
                state.actionLoading = false;
            })
            .addCase(applyForTrial.rejected, (state) => {
                state.errored = true;
                state.actionLoading = false;
            })
            .addCase(applyForEmailTrial.pending, (state) => {
                state.errored = false;
                state.actionLoading = true;
            })
            .addCase(applyForEmailTrial.fulfilled, (state) => {
                state.errored = false;
                state.actionLoading = false;
            })
            .addCase(applyForEmailTrial.rejected, (state) => {
                state.errored = true;
                state.actionLoading = false;
            })
            .addCase(acceptLicense.pending, (state) => {
                state.errored = false;
                state.actionLoading = true;
            })
            .addCase(acceptLicense.fulfilled, (state) => {
                state.actionLoading = false;
                state.licenseAccepted = true;
            })
            .addCase(acceptLicense.rejected, (state) => {
                state.errored = true;
                state.actionLoading = false;
            })
            .addCase(createUser.pending, (state) => {
                state.errored = false;
                state.actionLoading = true;
            })
            .addCase(createUser.fulfilled, (state) => {
                state.actionLoading = false;
            })
            .addCase(createUser.rejected, (state) => {
                state.errored = true;
                state.actionLoading = false;
            })
            .addCase(fetchChildUsers.pending, (state) => {
                state.errored = false;
                state.actionLoading = true;
            })
            .addCase(fetchChildUsers.fulfilled, (state, action) => {
                state.actionLoading = false;
                state.childUserList = action.payload;
            })
            .addCase(fetchChildUsers.rejected, (state) => {
                state.errored = true;
                state.actionLoading = false;
            })
            .addCase(updateChildUsers.pending, (state) => {
                state.errored = false;
                state.actionLoading = true;
            })
            .addCase(updateChildUsers.fulfilled, (state, action) => {
                state.actionLoading = false;
                state.childUserList = action.payload;
            })
            .addCase(updateChildUsers.rejected, (state) => {
                state.errored = true;
                state.actionLoading = false;
            });
    },
});

export const { setLoading, iterateQueryCount, reduceRemainingRows } = userSlice.actions;

export const selectEmail = (state: RootState) => state.user.email;
export const selectVerified = (state: RootState) => state.user.email_verified;
export const selectFirstName = (state: RootState) => state.user.firstName;
export const selectLastName = (state: RootState) => state.user.lastName;
export const selectCompany = (state: RootState) => state.user.company;
export const selectJobTitle = (state: RootState) => state.user.job_title;
export const selectIndustry = (state: RootState) => state.user.industry;
export const selectPhone = (state: RootState) => state.user.phone;
export const selectLicenceAccepted = (state: RootState) => state.user.licenseAccepted;
export const selectUserPermissions = (state: RootState) => state.user.permissions;
export const selectUserGracePeriod = (state: RootState) => state.user.gracePeriod;
export const selectIsParentUser = (state: RootState) => state.user.parentUser;
export const selectAdditionalUsers = (state: RootState) => state.user.additionalUsers;
export const selectInHouse = (state: RootState) => state.user.inHouse;
export const selectIsFirm = (state: RootState) => state.user.inHouse?.isFirm;
export const selectInHouseName = (state: RootState) => state.user.inHouse?.inHouseName;
export const selectLastChildModified = (state: RootState) => state.user.lastChildModified;

export const selectChildUsers = (state: RootState) => state.user.childUserList;
export const selectQueryCount = (state: RootState) => state.user.queryCount;
export const selectRowCount = (state: RootState) => state.user.rowCount;

export const selectUserLoading = (state: RootState) => state.user.loading;
export const selectActionLoading = (state: RootState) => state.user.actionLoading;
export const selectUserErrored = (state: RootState) => state.user.errored;
export const selectTooManySessions = (state: RootState) => state.user.tooManySessions;
export const selectUserProductType = (state: RootState) => state.user.permissions?.productType;

export default userSlice.reducer;
