import React from 'react';
import { committeeMember } from '../interfaces/committee-entity.interface';
import LoadingTable from '../../../components/generic-dashboards/loading/LoadingTable';
import CommitteeTable from './CommitteeTable';
import { Link } from 'react-router-dom';
import { MissingData } from '../../../components/misc/MissingData';
import { selectEmail, selectUserProductType } from '../../../store/reducers/userSlice';
import { useSelector } from 'react-redux';
import { GATrackDashboardEvent, EVENT_TYPES } from '../../../google_analytics/TrackEvent';

// Title and subtitle are optional components with defaults specified in function signature below.
interface committeeTableProps {
    topic?: string;
    dashboard?: string;
    title?: string;
    code?: string;
    tableData: committeeMember[] | null;
    loading: boolean;
    tableHeader: Array<string>;
    defaultSort?: string;
}

/*
    Component: Committeememberships.tsx
    Params: title - title of the row, subtitle - secondary title of the row
    Author: Fred Wang
    Desc: Wrapper component for table-based row. Features a full length table. Specifically for HoC dashboard use.
*/
const CommitteeMemberships = ({
    topic = '',
    dashboard = '',
    title = '',
    code = '',
    tableData,
    loading,
    tableHeader,
}: committeeTableProps) => {
    const userEmail = useSelector(selectEmail);
    const userProductType = useSelector(selectUserProductType);
    const TrackEvent = (name: string) => {
        GATrackDashboardEvent(
            name,
            EVENT_TYPES.CLICK,
            dashboard,
            title,
            topic,
            userEmail ? userEmail : undefined,
            userProductType ? userProductType : undefined
        );
    };
    return (
        <div>
            <div className="flex lg:flex-row flex-col justify-between lg:items-center">
                <div className="text-xl">{title}</div>
                <Link
                    target="_blank"
                    to={`https://www.ourcommons.ca/Committees/en/${code}`}
                    className="text-lg text-blue-500 hover:underline underline-offset-2"
                >
                    Committee Details
                </Link>
            </div>
            <hr className="h-0.5 my-3 bg-slate-100 rounded" />
            {loading ? (
                <LoadingTable />
            ) : !loading && !tableData ? (
                <MissingData />
            ) : tableData ? (
                <CommitteeTable
                    rowData={tableData}
                    rowHeaders={tableHeader}
                    defaultSort="party"
                    trackClickEvent={TrackEvent}
                />
            ) : (
                <></>
            )}
        </div>
    );
};

export default CommitteeMemberships;
