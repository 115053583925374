import React from 'react';
import { useNavigate } from 'react-router-dom';
import Check from '@mui/icons-material/CheckRounded';
import TimelineRoundedIcon from '@mui/icons-material/TimelineRounded';
import AutoGraphRoundedIcon from '@mui/icons-material/AutoGraphRounded';
import HubRoundedIcon from '@mui/icons-material/HubRounded';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import SpatialTrackingIcon from '@mui/icons-material/SpatialTracking';
import BarChartIcon from '@mui/icons-material/BarChart';
import MetaTag from '../components/metatag-component/MetaTag';

const PricingPage = () => {
    const navigate = useNavigate();

    // Component for product details with necessary parameters
    const card = (
        name: string,
        description: string,
        features: string[],
        icon?: JSX.Element,
        alt?: boolean
    ) => (
        <div
            className={`col-span-3 shadow-md lg:col-span-1 rounded-2xl flex flex-col justify-between ${
                alt ? 'bg-sky-900' : 'bg-white'
            }`}
        >
            <div className={`p-10 ${alt ? 'text-white' : 'text-slate-800'}`}>
                <div className="flex flex-row justify-between items-center font-bold text-3xl">
                    {name}
                    {icon}
                </div>
                {description !== '' && <div className="text-md mt-2 h-12">{description}</div>}

                <div className="mt-8">
                    <div
                        className={`py-4 text-slate-500 flex flex-col gap-2 text-md ${
                            alt ? 'text-white' : ''
                        }`}
                    >
                        {features.map((f, i) => (
                            <div key={i} className="flex flex-row gap-1 items-start">
                                <Check style={{ color: '#60a5fa' }} />
                                {f}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className="pt-4 grid grid-cols-12">
            <MetaTag
                title="Subscriptions"
                keywords="LobbyIQ Subscription Tiers, Request a Call, Demonstration, Stakeholder Insights, Monitoring, Professional Tier, Corporate Tier, Enterprise Tier, Committee Package, Issue Tracking Package, GR Insider Package, Sector Dashboards, Institution Dashboards, Committee Dashboards, Organization Dashboards, Subject Dashboards, Custom Data Queries, Weekly Email Updates, Monthly Round-Up Newsletters, Business Development Indicators"
                description="Explore LobbyIQ's subscription tiers tailored for corporations, trade associations, and advisory firms. Gain insights with sector, institution, and committee dashboards, custom data queries, and real-time updates. Packages include Professional, Corporate, Enterprise, Committee Package, Issue Tracking Package, and GR Insider Package, each offering unique features like same-day transcripts, issue tracking, and business development indicators."
            />
            <div className="col-start-1 col-end-13 grid grid-cols-12 bg-slate-100 mt-12 pt-12 pb-32">
                <div className="col-start-2 col-end-12 flex flex-col text-left mb-10">
                    <div className="text-4xl">
                        Lobby
                        <span className="font-black">IQ</span> Subscription Tiers
                    </div>
                </div>
                <div className="col-start-2 col-end-12 pb-8">
                    <div
                        className="flex hover:cursor-pointer rounded-2xl bg-blue-400 p-5 text-2xl text-white content-center justify-center"
                        onClick={() => navigate('/request')}
                    >
                        Request a Call or Demonstration
                    </div>
                </div>
                <div className="col-start-2 col-end-12 p-5 pb-10 mb-10 shadow-md rounded-2xl bg-gray-200">
                    <div className="font-bold text-3xl mb-5">
                        Stakeholder Insights and Monitoring
                    </div>
                    <div className="grid grid-cols-3 gap-8">
                        {card(
                            'Professional',
                            'Recommended for corporations and organizations operating in one or two sectors',
                            [
                                '2 Sector Dashboards',
                                '10 Institution Dashboards',
                                '2 Committee Dashboards',
                                'Organization Dashboards for selected Sectors',
                                'All Subject Dashboards',
                                'Custom data queries',
                                'Weekly email updates',
                                'Monthly round-up newsletters',
                                'Up to 2 parallel users per account',
                            ],
                            <TimelineRoundedIcon style={{ fontSize: '40px' }} />
                        )}
                        {card(
                            'Corporate',
                            'Recommended for multi-sector corporations and trade associations',
                            [
                                'Up to 8 Sector Dashboards',
                                'Up to 30 Institution Dashboards',
                                'Up to 6 Committee Dashboards',
                                'Organization Dashboards for selected Sectors',
                                'All Subject Dashboards',
                                'Custom data queries',
                                'Weekly email updates',
                                'Monthly round-up newsletters',
                                'Up to 5 parallel users per account',
                            ],
                            <AutoGraphRoundedIcon style={{ fontSize: '40px' }} />
                        )}
                        {card(
                            'Enterprise',
                            'Recommended for professional advisory firms with clients in multiple sectors',
                            [
                                'All Sector Dashboards',
                                'All Institution Dashboards',
                                'All Committee Dashboards',
                                'All Organization Dashboards',
                                'All Subject Dashboards',
                                'Custom data queries',
                                'Weekly email updates',
                                'Monthly round-up newsletters',
                                'Additional users under one license',
                            ],
                            <HubRoundedIcon style={{ fontSize: '40px' }} />,
                            true
                        )}
                    </div>
                </div>
                <div className="col-start-2 col-end-12 grid grid-cols-3 gap-8">
                    {card(
                        'Committee Package',
                        '',
                        [
                            'Same-day transcripts for federal (H and S) and provincial (ON) Committee meetings',
                            'Same-day summaries of Committee meetings',
                        ],
                        <BarChartIcon style={{ fontSize: '40px' }} />
                    )}
                    {card(
                        'Issue Tracking Package',
                        '',
                        [
                            'Custom Issue Dashboards',
                            'Bill Dashboards (beta)',
                            'Weekly email updates',
                        ],
                        <TroubleshootIcon style={{ fontSize: '40px' }} />
                    )}
                    {card(
                        'GR Insider Package',
                        '',
                        [
                            'Lobby Firm Dashboards',
                            'Consultant Dashboards',
                            'Business development indicators',
                            'Weekly email updates',
                        ],
                        <SpatialTrackingIcon style={{ fontSize: '40px' }} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default PricingPage;
