import { useEffect, useState } from 'react';
import { Alert, Breadcrumbs, Button, Slide, Snackbar } from '@mui/material';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import DateSelection from '../../components/misc/DateSelection';
import { Link, useLocation, useParams } from 'react-router-dom';
import {
    entityLobbyingActivity,
    entityOrg,
    entityOverviews,
} from '../../interfaces/generic-entity.interface';
import Overview from '../../components/generic-dashboards/Overview';
import Breakdowns from '../../components/generic-dashboards/Breakdowns';
import DashTable from '../../components/generic-dashboards/DashTable';
import { entityActivity } from '../../interfaces/organization-entity.interface';
import { DPOHOverview } from '../../tooltips/dpoh/DPOHOverview';
import { DPOHLobbyingCommunications } from '../../tooltips/dpoh/DPOHLobbyingCommunications';
import { DPOHCommunications } from '../../tooltips/dpoh/DPOHCommunications';
import { DPOHOrganizations } from '../../tooltips/dpoh/DPOHOrganizations';
import { useSelector } from 'react-redux';
import { EVENT_TYPES, GATrackDashboardLoad } from '../../google_analytics/TrackEvent';
import { selectEmail, selectUserProductType } from '../../store/reducers/userSlice';
import SimCardDownloadRoundedIcon from '@mui/icons-material/SimCardDownloadRounded';
import { DashboardDownloadModal } from '../../components/misc/DashboardDownloadModal';
import { bumperSuperList } from '../../interfaces/bumper-types.interface';
import IssueDashTable from '../../features/issue-dashboards/UI/IssueDashTable';
import { mpBiography } from '../../features/dpoh-dashboards/interface/dpoh-entity.interface';
import MPBioInfo from '../../features/dpoh-dashboards/UI/MPBioInfo';

interface DPOHDashboardProps {
    name: string;
    date: string;
    handleDateChange: (x: string) => void;
    overview: entityOverviews | null;
    overviewLoading: boolean;
    overviewHeaders: string[];
    overviewIndent: string[];
    bio: mpBiography | null;
    bioLoading: boolean;
    bumperData: bumperSuperList[] | null;
    bumperDataLoading: boolean;
    topPartners: entityOrg[] | null;
    topPartnersLoading: boolean;
    topSectors: entityOrg[] | null;
    topSectorsLoading: boolean;
    topSubjects: entityOrg[] | null;
    topSubjectsLoading: boolean;
    recentActivity: entityLobbyingActivity[] | null;
    recentActivityLoading: boolean;
    orgActivity: entityActivity[] | null;
    orgActivityLoading: boolean;
    isMP: boolean;
    exportLoading: boolean;
    exportFail: boolean;
}

const DPOHDashboard = ({
    name,
    date,
    handleDateChange,
    overview = { yearly: [], monthly: [] },
    overviewLoading,
    overviewHeaders,
    overviewIndent = [],
    bio = null,
    bioLoading = false,
    bumperData = null,
    bumperDataLoading = false,
    topPartners,
    topPartnersLoading,
    topSectors,
    topSectorsLoading,
    topSubjects,
    topSubjectsLoading,
    recentActivity,
    recentActivityLoading,
    orgActivity,
    orgActivityLoading,
    isMP,
    exportLoading,
    exportFail,
}: DPOHDashboardProps) => {
    const [dateOpen, setDateOpen] = useState<boolean>(false);
    const [dateSet, setDateSet] = useState<boolean>(false);
    const [exportModalOpen, setExportModalOpen] = useState<boolean>(false);

    const handleDateOpen = () => setDateOpen(true);
    const handleDateClose = () => {
        setDateOpen(false);
        setDateSet(true);
    };

    const [scrollPosition, setScrollPosition] = useState(0);

    const userEmail = useSelector(selectEmail);
    const userProductType = useSelector(selectUserProductType);

    useEffect(() => {
        GATrackDashboardLoad(
            'DPOH',
            `${name}`,
            EVENT_TYPES.PAGE_LOAD,
            userEmail ? userEmail : undefined,
            userProductType ? userProductType : undefined
        );
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            setScrollPosition(scrollPosition);
        };
        window.addEventListener('scroll', handleScroll);
        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [name, userEmail, userProductType]);

    const url_params = useParams();
    const location = useLocation();

    const institution_id: string | undefined =
        url_params.entityId !== undefined ? url_params.entityId : '';
    const institution_name: string = new URLSearchParams(location.search).get('institution') || '';

    return (
        <div className="py-32 lg:px-20 px-4 grid grid-cols-12 texst-">
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={dateSet}
                onClose={() => setDateSet(false)}
                autoHideDuration={3000}
                message="Date Set"
            >
                <Alert onClose={() => setDateSet(false)} severity="success">
                    Date set to {date}
                </Alert>
            </Snackbar>

            <Slide direction="left" in={scrollPosition >= 50} mountOnEnter unmountOnExit>
                <div className="fixed z-20 bottom-24 right-5 gap-2 lg:top-20 lg:right-4 flex flex-col h-0">
                    <Button
                        className="group flex"
                        onClick={handleDateOpen}
                        variant="contained"
                        startIcon={<CalendarMonthRoundedIcon />}
                    >
                        {date}
                    </Button>
                    <Button
                        variant="contained"
                        startIcon={<SimCardDownloadRoundedIcon />}
                        onClick={() => setExportModalOpen(true)}
                    >
                        Save PDF
                    </Button>
                </div>
            </Slide>

            <DateSelection
                open={dateOpen}
                handleClose={handleDateClose}
                handleDateChange={handleDateChange}
                views={['year', 'month', 'day']}
            />
            <DashboardDownloadModal
                title={name || 'Dashboard'}
                type={'dpoh'}
                modalOpen={exportModalOpen}
                handleClose={() => setExportModalOpen(false)}
                overview={overview}
                lobbyingBreakdown={
                    topPartners && topSectors && topSubjects
                        ? { cluster_1: topPartners, cluster_2: topSubjects, cluster_3: topSectors }
                        : null
                }
                recentActivity={recentActivity}
                orgActivity={orgActivity}
                exportLoading={exportLoading}
                exportFail={exportFail}
            />

            <div className=" col-start-1 col-end-13 flex flex-row justify-between">
                <Breadcrumbs className="col-start-2 lg:col-end-12 col-end-12" separator=">">
                    <Link to="/" className="hover:underline">
                        Lobby<b>IQ</b>
                    </Link>
                    <Link to={`/institutions`} className="hover:underline">
                        Institutions
                    </Link>
                    <Link
                        to={`/institutions/${encodeURIComponent(
                            institution_id
                        )}?name=${encodeURIComponent(institution_name)}`}
                        className="hover:underline"
                    >
                        {institution_name}
                    </Link>
                    <Link
                        to={
                            isMP
                                ? '/institutions/103/dpoh?institution=House%20of%20Commons&title=Member%20of%20Parliament'
                                : `/institutions/${encodeURIComponent(
                                      institution_id
                                  )}/dpoh?institution=${encodeURIComponent(institution_name)}`
                        }
                        className="hover:underline"
                    >
                        {isMP ? 'Members of Parliament' : 'DPOHs'}
                    </Link>
                    <div>{name}</div>
                </Breadcrumbs>
                <Slide direction="left" in={true}>
                    <div className="lg:flex hidden flex-row gap-4">
                        <Button
                            onClick={handleDateOpen}
                            variant="outlined"
                            startIcon={<CalendarMonthRoundedIcon />}
                        >
                            Data From: {date}
                        </Button>
                        <Button
                            variant="outlined"
                            startIcon={<SimCardDownloadRoundedIcon />}
                            onClick={() => setExportModalOpen(true)}
                        >
                            Save PDF
                        </Button>
                    </div>
                </Slide>
            </div>

            {isMP && (
                <div className="mt-4 col-start-1 col-end-13">
                    <MPBioInfo mpName={name} bioData={bio} loading={bioLoading} />
                </div>
            )}

            <div className={`${isMP ? 'mt-16' : 'mt-4'} col-start-1 col-end-13`}>
                <Overview
                    dashboard="DPOH"
                    topic={name}
                    title="DPOH Overview"
                    subtitle={name}
                    tooltipContent={DPOHOverview}
                    barChartTitle="Communications (18 months)"
                    overviews={overview}
                    overviewIndent={overviewIndent}
                    loading={overviewLoading}
                    tableHeader={overviewHeaders}
                    primaryColor="rgb(14 116 144)"
                    secondaryColor="rgb(6 182 212)"
                />
            </div>

            {isMP && (
                <div className="mt-16 col-start-1 col-end-13">
                    <IssueDashTable
                        topic={name}
                        title={`Mentions of Issue Keywords in Hansard ${name ? 'by ' + name : ''}`}
                        subtitle="Past 3 Months"
                        tableData={bumperData}
                        loading={bumperDataLoading}
                        filterBy="issue"
                        defaultNumRows={5}
                    />
                </div>
            )}

            <div className="mt-16 col-start-1 col-end-13">
                <Breakdowns
                    title="Lobbying Communications"
                    subtitle="Past 12 Months"
                    tooltipContent={DPOHLobbyingCommunications}
                    breakdownTitle1="Lobbying Reports by Sector (12M)"
                    breakdownData1={topSectors}
                    loading1={topSectorsLoading}
                    breakdownTitle2="Lobbying Reports by Subject (12M)"
                    breakdownData2={topSubjects}
                    loading2={topSubjectsLoading}
                    breakdownTitle3="Lobbying Reports by Type (12M)"
                    breakdownData3={topPartners}
                    loading3={topPartnersLoading}
                />
            </div>

            <div className="mt-12 col-start-1 col-end-13">
                <DashTable
                    dashboard="DPOH"
                    topic={name}
                    title="Recent Lobbying Communications"
                    subtitle="Past 12 Months"
                    tableData={recentActivity}
                    loading={recentActivityLoading}
                    tooltipContent={DPOHCommunications}
                    tableHeader={[
                        'date',
                        'organization',
                        'sector',
                        'lobby_firm',
                        'consultant',
                        'Prior Comms (36M)',
                        'link',
                    ]}
                />
            </div>

            <div className="mt-16 col-start-1 col-end-13">
                <DashTable
                    dashboard="DPOH"
                    topic={name}
                    title="Organizations Lobbying"
                    subtitle="Past 12 Months"
                    tooltipContent={DPOHOrganizations}
                    tableData={orgActivity}
                    loading={orgActivityLoading}
                    tableHeader={[
                        'name',
                        'sector',
                        'prior_comms_(12M)',
                        'prior_comms_(36M)',
                        'percent_comms_(12M)',
                    ]}
                    suffixes={{ 'percent_comms_(12M)': '%' }}
                    defaultSort="percent_comms_(12M)"
                />
            </div>
        </div>
    );
};

export default DPOHDashboard;
