import React, { useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { useAppDispatch } from '../../store/store';
import { useSelector } from 'react-redux';
import {
    selectFirstName,
    selectLastName,
    selectCompany,
    selectJobTitle,
    selectPhone,
    selectEmail,
    selectIndustry,
    selectUserLoading,
    applyForTrial,
    selectActionLoading,
    selectUserErrored,
    selectUserPermissions,
    ProductType,
} from '../../store/reducers/userSlice';
import LoadPage from '../misc/LoadPage';
import { AccountRequired } from '../misc/AccountRequired';
import LoadSpinner from '../../components/misc/LoadSpinner';

const TrialPage = () => {
    const dispatch = useAppDispatch();

    const userLoading = useSelector(selectUserLoading);
    const trialLoading = useSelector(selectActionLoading);
    const trialError = useSelector(selectUserErrored);

    const permissions = useSelector(selectUserPermissions);
    const existingEmail = useSelector(selectEmail);
    const existingFirstName = useSelector(selectFirstName);
    const existingLastName = useSelector(selectLastName);
    const existingCompany = useSelector(selectCompany);
    const existingJobTitle = useSelector(selectJobTitle);
    const existingIndustry = useSelector(selectIndustry);
    const existingPhone = useSelector(selectPhone);

    const [firstName, setFirstName] = useState(existingFirstName || '');
    const [lastName, setLastName] = useState(existingLastName || '');
    const [company, setCompany] = useState(existingCompany || '');
    const [industry, setIndustry] = useState(existingIndustry || '');
    const [jobTitle, setJobTitle] = useState(existingJobTitle || '');
    const [phone, setPhone] = useState(existingPhone || '');

    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [industryError, setIndustryError] = useState(false);
    const [companyError, setCompanyError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [jobTitleError, setJobTitleError] = useState(false);

    const [resultModalOpen, setResultModalOpen] = React.useState(permissions?.productType === ProductType.TRIAL || false);

    const handleClickOpen = () => {
        setResultModalOpen(true);
    };

    const handleClose = () => {
        setResultModalOpen(false);
    };

    const sendMessage = async () => {
        dispatch(
            applyForTrial({
                firstName,
                lastName,
                company,
                job_title: jobTitle,
                industry,
                phone,
                email: existingEmail!,
            })
        ).catch((error: any) => {
            console.log(error);
        });
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setFirstNameError(false);
        setLastNameError(false);
        setIndustryError(false);
        setCompanyError(false);
        setPhoneError(false);
        setJobTitleError(false);

        // check for required fields
        if (firstName === '') {
            setFirstNameError(true);
        }
        if (lastName === '') {
            setLastNameError(true);
        }
        if (company === '') {
            setCompanyError(true);
        }
        if (industry === '') {
            setIndustryError(true);
        }
        if (phone.length > 10 || phone.length > 11) {
            setPhoneError(true);
        }
        if (jobTitle === '') {
            setJobTitleError(true);
        }

        if (
            !firstNameError &&
            !lastNameError &&
            !industryError &&
            !phoneError &&
            !companyError &&
            !jobTitleError
        ) {
            await sendMessage();

            handleClickOpen();
        }
    };

    return userLoading ? (
        <LoadPage />
    ) : existingEmail ? (
        <div className="lg:p-16 lg:pt-32 pt-24 p-10 mb-16">
            <Dialog
                open={resultModalOpen}
                onClose={trialLoading ? () => {} : handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {trialLoading
                        ? 'Processing Trial Request'
                        : trialError
                        ? 'Error'
                        : 'Request Sent'}
                </DialogTitle>
                <DialogContent>
                    {trialLoading ? (
                        <div className="flex flex row justify-center items-center">
                            <LoadSpinner />
                        </div>
                    ) : (
                        <DialogContentText id="alert-dialog-description">
                            {trialError
                                ? 'Error submitting trial application. Please refresh the page and try again.' :
                                permissions?.productType === ProductType.TRIAL ?
                                'You have been approved for a 48 hour trial of LobbyIQ.'
                                : 'Thank you for applying for a LobbyIQ trial. Upon review we will email you an approval link, or request additional information.'}
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        autoFocus
                        disabled={trialLoading}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <div className="flex flex-col justify-center items-center text-center mb-12">
                <div className="mb-5 lg:text-2xl">
                    Please fill out this form to request a 48 hour free trial
                </div>
            </div>
            <form autoComplete="off" onSubmit={handleSubmit}>
                <div className="flex lg:flex-row md:flex-row flex-col justify-center items-center lg:pb-8 pb-6">
                    <div className="mb-5 lg:mb-0 md:mb-0">
                        <div className="p-3">First Name</div>
                        <div className="lg:w-96 w-72 lg:pr-4 md:pr-4">
                            <TextField
                                fullWidth
                                id="first-name"
                                placeholder="First Name"
                                variant="outlined"
                                required
                                onChange={(e) => setFirstName(e.target.value)}
                                value={firstName}
                                error={firstNameError}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="lg:pl-6 md:pl-6 p-3">Last Name</div>
                        <div className="lg:w-96 w-72 lg:pl-4 md:pl-4">
                            <TextField
                                fullWidth
                                id="last-name"
                                placeholder="Last Name"
                                variant="outlined"
                                required
                                onChange={(e) => setLastName(e.target.value)}
                                value={lastName}
                                error={lastNameError}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex lg:flex-row md:flex-row flex-col justify-center items-center lg:pb-8 pb-6">
                    <div className="mb-5 lg:mb-0 md:mb-0">
                        <div className="p-3">Industry</div>
                        <div className="lg:w-96 w-72 lg:pr-4 md:pr-4">
                            <TextField
                                fullWidth
                                id="industry"
                                placeholder="Oil, Telecoms, etc."
                                variant="outlined"
                                required
                                onChange={(e) => setIndustry(e.target.value)}
                                value={industry}
                                error={industryError}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="lg:pl-6 md:pl-6 p-3">Phone Number</div>
                        <div className="lg:w-96 w-72 lg:pl-4 md:pl-4">
                            <TextField
                                fullWidth
                                id="phone-num"
                                placeholder="+1 123-456-7890"
                                variant="outlined"
                                required
                                onChange={(e) =>
                                    setPhone(e.target.value.replace(/\D/g, ''))
                                }
                                value={phone}
                                error={phoneError}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex lg:flex-row md:flex-row flex-col justify-center items-center lg:pb-12 md:pb-10 pb-6">
                    <div className="mb-5 lg:mb-0 md:mb-0">
                        <div className="p-3">Company/Organization</div>
                        <div className="lg:w-96 w-72 lg:pr-4 md:pr-4">
                            <TextField
                                fullWidth
                                id="comp-org"
                                placeholder="Company/Organization"
                                variant="outlined"
                                required
                                onChange={(e) => setCompany(e.target.value)}
                                value={company}
                                error={companyError}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="lg:pl-6 md:pl-6 p-3">Job Title</div>
                        <div className="lg:w-96 w-72 lg:pl-4 md:pl-4">
                            <TextField
                                fullWidth
                                id="occupation"
                                placeholder="Head of Marketing"
                                variant="outlined"
                                required
                                onChange={(e) => setJobTitle(e.target.value)}
                                value={jobTitle}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex flex-row justify-center items-center lg:mt-16 md:mt-10 mt-4">
                    <Button
                        className="bg-slate-600"
                        variant="contained"
                        size="large"
                        type="submit"
                        endIcon={<SendIcon />}
                    >
                        <div className="font-sans not-italic font-bold text-normal">
                            Request
                        </div>
                    </Button>
                </div>
            </form>
        </div>
    ) : (
        <AccountRequired />
    );
};

export default TrialPage;