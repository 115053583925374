import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../../store/store';
import { entityList, entityListExtra } from '../../../interfaces/generic-entity.interface';
import { percentage_change_num } from '../../../helpers/percentage_change';

const allowed_committees: Array<any> = [
    'ACVA',
    'AGRI',
    'CHPC',
    'CIIT',
    'CIMM',
    'ENVI',
    'ETHI',
    'FAAE',
    'FEWO',
    'FINA',
    'FOPO',
    'HESA',
    'HUMA',
    'INAN',
    'INDU',
    'JUST',
    'LANG',
    'NDDN',
    'OGGO',
    'PACP',
    'PROC',
    'RNNR',
    'SECU',
    'SRSR',
    'TRAN',
];

interface CommitteeListState {
    list: entityListExtra[] | null;
    loading: boolean;
    mp_list: entityListExtra[] | null;
    mp_list_loading: boolean;
}

const initialState: CommitteeListState = {
    list: null,
    loading: false,
    mp_list: null,
    mp_list_loading: false,
};

const baseURL = process.env.REACT_APP_API_BASE_URL;

export const fetchCommitteeList = createAsyncThunk(
    'committee/fetchList',
    async (): Promise<entityListExtra[]> => {
        const response = await axios.get(`${baseURL}/hoc/list`);
        const data: entityList[] = response.data.committee;
        const converted_data: entityListExtra[] = data
            .filter((entry) => allowed_committees.includes(entry.code))
            .map((entry) => {
                return {
                    ...entry,
                    // Calculate with potential nulls replaced by 0
                    change_abs: (entry.last_month || 0) - (entry.two_months_ago || 0),
                    change_rel: percentage_change_num(
                        entry.two_months_ago || 0,
                        entry.last_month || 0
                    ),
                    available: true,
                };
            });
        return converted_data;
    }
);

export const fetchMPList = createAsyncThunk(
    'committee/fetchMPList',
    async ({ code }: { code: string | undefined }): Promise<entityListExtra[]> => {
        const response = await axios.get(`${baseURL}/hoc/${code}/mpaggregates`);
        const data: entityList[] = response.data.mpList;

        const converted_data: entityListExtra[] = data.map((entry) => {
            return {
                ...entry,
                // Calculate with potential nulls replaced by 0
                change_abs: (entry.last_month || 0) - (entry.two_months_ago || 0),
                change_rel: percentage_change_num(entry.two_months_ago || 0, entry.last_month || 0),
                available: true,
            };
        });
        return converted_data;
    }
);

const committeeListSlice = createSlice({
    name: 'committeeListReducer',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCommitteeList.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchCommitteeList.fulfilled, (state, action) => {
                state.loading = false;
                state.list = action.payload;
            })
            .addCase(fetchCommitteeList.rejected, (state) => {
                state.loading = false;
                state.list = null;
            })
            .addCase(fetchMPList.pending, (state) => {
                state.mp_list_loading = true;
            })
            .addCase(fetchMPList.fulfilled, (state, action) => {
                state.mp_list_loading = false;
                state.mp_list = action.payload;
            })
            .addCase(fetchMPList.rejected, (state) => {
                state.mp_list_loading = false;
                state.mp_list = null;
            });
    },
});

export const selectCommitteeList = (state: RootState) => state.committeeList.list;
export const selectCommitteeListLoading = (state: RootState) => state.committeeList.loading;

export const selectMPList = (state: RootState) => state.committeeList.mp_list;
export const selectMPListLoading = (state: RootState) => state.committeeList.mp_list_loading;

export default committeeListSlice.reducer;
