import React, { useState } from 'react';
import Modal from './Modal';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import { LicenseAgreement } from '../../assets/LicenseAgreement';
import { useAppDispatch } from '../../store/store';
import {
    acceptLicense,
    fetchActiveUser,
    redirectToPayment,
    selectActionLoading,
    selectUserErrored,
} from '../../store/reducers/userSlice';
import { useSelector } from 'react-redux';
import LoadSpinner from './LoadSpinner';

interface licenseAgreementModalProps {
    open: boolean;
    handleClose: () => void;
    selectedProductId: string;
    disableProductRedirect?: boolean;
}

const LicenseAgreementModal = ({
    open,
    handleClose,
    selectedProductId,
    disableProductRedirect = false,
}: licenseAgreementModalProps) => {
    const dispatch = useAppDispatch();

    const loading = useSelector(selectActionLoading);
    const errored = useSelector(selectUserErrored);

    const [licenseAgreed, setLicenseAgreed] = useState(false);

    const handleSubmit = async () => {
        if (licenseAgreed) {
            await dispatch(acceptLicense()).unwrap();
            if (!disableProductRedirect) {
                await dispatch(redirectToPayment(selectedProductId)).unwrap();
            } else {
                await dispatch(fetchActiveUser()).unwrap();
                handleClose();
            }
        }
    };

    return (
        <Modal
            title={'Please accept the License Agreement before continuing'}
            open={open}
            width={40}
            onClose={handleClose}
        >
            <textarea
                className="w-full h-96 resize-none mt-2 p-2"
                readOnly
                value={LicenseAgreement}
            />

            <div className="flex flex-col center">
                <div>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={licenseAgreed}
                                onChange={() =>
                                    setLicenseAgreed(!licenseAgreed)
                                }
                            />
                        }
                        label={<p>I accept the License Agreement</p>}
                    />
                </div>
                <a
                    href={'/LobbyIQ_User_License_Agreement.pdf'}
                    download="LobbyIQ_User_License_Agreement"
                    target="_blank"
                    rel="noreferrer"
                >
                    <div
                        className="hover:underline hover:cursor-pointer text-blue-400"
                    >
                        Download
                    </div>
                </a>
                {errored && (
                    <div className="flex flex-row mt-5 justify-center">
                        <div className="text-red-600">
                            Please check your network connection and try again.
                        </div>
                    </div>
                )}
                <div className="flex justify-center mt-2">
                    {loading ? (
                        <LoadSpinner />
                    ) : (
                        <Button
                            variant="contained"
                            sx={{ width: '75%' }}
                            disabled={!licenseAgreed || loading}
                            onClick={handleSubmit}
                        >
                            Continue
                        </Button>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default LicenseAgreementModal;
