import { Link } from 'react-router-dom';
import MetaTag from '../../components/metatag-component/MetaTag';

const ParliamentMonitor = () => {
    return (
        <div className="xl:mx-24 mx-8 ">
            <MetaTag
                title="Parliament Monitoring"
                keywords="Monitor Committees, House of Commons, Senate, Committee Dashboards, Lobbying, MPs, Parliamentarians, Key Policy Issues, Committee Meetings, Provincial Committee Summaries, Same-Day Summaries, Transcripts, Meeting Summaries, Critical Committee Minutes, Provincial Committees, ON"
                description="Monitor Committees with House of Commons and Senate committee dashboards tracking lobbying activities of MPs and key policy issues discussed. Access MP and Senator dashboards to understand individual parliamentarians' key issues. Beta feature offers same-day summaries of provincial committee meetings in Ontario. Get tired of waiting for critical committee transcripts? Receive full meeting transcripts and 2-page summaries within the hour of the meeting."
            />
            <div className="col-12 md:col-7 lg:col-6 py-14 lg:gap-12 gap-y-8 lg:gap-y-0"></div>

            <div className="lg:grid grid-cols-12 grid-rows-auto gap-8 py-8 flex flex-col ">
                <div className="col-start-1 row-start-1 col-span-12 col-end-12 text-left">
                    <h1 className="lg:text-4xl text-3xl ">Parliament Monitoring</h1>
                </div>

                <div className="lg:col-start-1 row-start-2 col-start-2 col-end-7 grid grid-cols-12 gap-6">
                    <div className="lg:col-span-6 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between min-h-[20rem]">
                        <div>
                            <div className="text-2xl">
                                Monitor{' '}
                                <span className="text-liq-lime hover:underline">
                                    <Link to="/committees">Committees</Link>
                                </span>
                            </div>
                            <div className="text-md mt-8 font-light">
                                House of Commons and Senate committee dashboards track lobbying of
                                the MPs on each committee and the key policy issues being discussed
                                during committee meetings.
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-span-6 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between min-h-[20rem]">
                        <div>
                            <div className="text-2xl">
                                Monitors{' '}
                                <span className="text-liq-teal hover:underline">
                                    <Link to="/committees/AGRI/mps?committee=Agriculture%20and%20Agri-Food">
                                        MPs
                                    </Link>
                                </span>
                            </div>
                            <div className="text-md mt-8 font-light">
                                MP and Senator dashboards track lobbying and what each
                                parliamentarian's key issues are.
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-span-6 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between min-h-[20rem]">
                        <div>
                            <div className="text-2xl">Provincial Committee Summaries</div>
                            <div className="text-md mt-8 font-light">
                                in beta: same-day summaries of provincial committee meetings (ON)
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-span-6 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between min-h-[20rem]">
                        <div>
                            <div className="text-2xl">
                                Same-Day{' '}
                                <span className="text-liq-lime hover:underline">
                                    <a href=" https://liqtranscripts.blob.core.windows.net/summary/AGRI_2024-02-27.pdf">
                                        Committee Meeting Summaries
                                    </a>
                                </span>
                            </div>
                            <div className="text-md mt-8 font-light">
                                Tired of waiting two weeks to obtain transcripts from critical
                                committee minutes? We deliver full meeting transcripts and 2-page
                                summaries to your inbox within the hour of the meeting
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-start-7 col-span-6 flex justify-center items-center">
                    <img
                        src={process.env.PUBLIC_URL + '/Committee_Overview.png'}
                        className="w-full bg-slate-50 border border-slate-100 p-6 rounded-xl shadow-md	"
                        height="auto"
                        alt="logo"
                    />
                </div>
            </div>

            <div className="col-12 md:col-7 lg:col-6 py-14 lg:gap-12 gap-y-8 lg:gap-y-0"></div>
        </div>
    );
};

export default ParliamentMonitor;
