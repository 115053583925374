import { useNavigate } from 'react-router-dom';
import logo from '../../assets/vectors/LIQ_badge.png';

interface SubscriptionRequiredProps {
    minimumTier?: string;
    pageName?: string;
}

export const SubscriptionRequired = ({
    pageName,
    minimumTier,
}: SubscriptionRequiredProps) => {
    const navigate = useNavigate();

    const handleRedirect = async () => {
        navigate('/pricing');
    };

    return (
        <div className="flex flex-row items-center justify-center text-xl py-[50vh]">
            <img src={logo} className="h-20" alt="logo" />
            <div style={{ marginLeft: '20px', marginRight: '20px' }} />
            <div className="flex flex-col sm:flex-row">
                {pageName ? <p>{`${pageName} available with a `} </p> :
                <p>This page requires a </p>
                }
                <p className="font-bold sm:ml-1">{minimumTier}</p>
                <div
                    className="sm:mx-1 font-bold text-cyan-500 hover:underline hover:cursor-pointer"
                    onClick={handleRedirect}
                >
                    subscription.
                </div>
            </div>
        </div>
    );
};
