import { Link, useNavigate } from 'react-router-dom';
import LoadPage from '../misc/LoadPage';
import { useSelector } from 'react-redux';
import {
    selectUserLoading,
    selectUserPermissions,
    ProductType,
} from '../../store/reducers/userSlice';
import { SubscriptionRequired } from '../misc/SubscriptionRequired';
import logo from '../../assets/vectors/LIQ_badge.png';
import PersonIcon from '@mui/icons-material/Person';
import FeedIcon from '@mui/icons-material/Feed';
import TuneIcon from '@mui/icons-material/Tune';

const AccountPage = () => {
    const navigate = useNavigate();

    const userLoading = useSelector(selectUserLoading);
    const permissions = useSelector(selectUserPermissions);

    return userLoading ? (
        <LoadPage />
    ) : !permissions ? (
        <SubscriptionRequired />
    ) : permissions.productType === ProductType.TRIAL ? (
        <div className="flex flex-col sm:flex-row items-center justify-center py-[50vh] text-lg">
            <img src={logo} className="h-20" alt="logo" />
            <div style={{ marginLeft: '20px', marginRight: '20px' }} />
            <p>Free Trial has no customizable settings.</p>
            <div className="flex flex-row items-center justify-center align-middle ml-2">
                <p>Click</p>
                <div
                    className="mx-1 text-cyan-500 font-bold hover:underline hover:cursor-pointer"
                    onClick={() => navigate('/')}
                >
                    here
                </div>
                <p>to go back.</p>
            </div>
        </div>
    ) : (
        <div className="flex flex-row items-center justify-center py-[50vh]">
            <img src={logo} className="h-20" alt="logo" />
            <div style={{ marginLeft: '20px', marginRight: '20px' }} />
            <div>
                    {permissions.productType === ProductType.ENTERPRISE && (
                        <div className="text-blue-400 bg-white shadow-sm rounded-md p-2 text-lg">
                            <PersonIcon />
                            <Link to="/account/enterprise" className="hover:underline ml-3">
                                Manage authorized users
                            </Link>
                        </div>
                    )}
                    {permissions.productType !== ProductType.ENTERPRISE && (
                        <div className="text-blue-400 bg-white shadow-sm rounded-md p-2 text-lg">
                            <FeedIcon />
                            <Link to="/account/selections" className="hover:underline ml-3">
                                Manage Dashboard Selections
                            </Link>
                        </div>
                    )}
                    {permissions.productType !== ProductType.PROFESSIONAL && (
                        <div className="text-blue-400 bg-white shadow-sm rounded-md p-2 text-lg">
                            <TuneIcon />
                            <Link to="/account/custom" className="hover:underline ml-3">
                                Custom Issue Creation
                            </Link>
                        </div>
                    )}
                {/* permissions.productType === ProductType.ENTERPRISE &&
                <div className="text-blue-400 bg-white shadow-sm rounded-md p-2 text-lg">
                    <FeedIcon />
                    <Link
                        className="hover:underline ml-3"
                        to="/account/newsletters"
                    >
                        Select sector newsletters
                    </Link>
                </div> */}
            </div>
        </div>
    );
};

export default AccountPage;
