import { Button } from '@mui/material';
import { bumperSuperList } from '../../interfaces/bumper-types.interface';
import {
    entityOverviews,
    entityLobbyingActivity,
    entityRegistration,
    entityCluster,
    entityLobbyingBreakdown,
    twoEntityLobbyingBreakdowns,
} from '../../interfaces/generic-entity.interface';
import Modal from './Modal';
import { useAppDispatch } from '../../store/store';
import { exportSectorDashboard } from '../../features/sector-dashboards/store/sectorSlice';
import { useEffect, useState } from 'react';
import { ExportExhibitSelector } from './ExportExhibitSelector';
import LoadSpinner from './LoadSpinner';
import {
    RegistrationDPOHActivity,
    entityActivity,
} from '../../interfaces/organization-entity.interface';
import { exportOrganizationDashboard } from '../../store/reducers/organizationSlice';
import { committeeMember } from '../../features/committee-dashboards/interfaces/committee-entity.interface';
import { exportCommitteeDashboard } from '../../features/committee-dashboards/store/committeeSlice';
import { exportDPOHDashboard } from '../../features/dpoh-dashboards/store/DPOHSlice';
import { exportSubjectDashboard } from '../../features/subject-dashboards/store/subjectSlice';
import {
    issueInfo,
    keywordMentions,
} from '../../features/issue-dashboards/interface/issue-entity.interface';
import { exportIssueDashboard } from '../../features/issue-dashboards/store/issueSlice';
import { exportLobbyFirmDashboard } from '../../store/reducers/lobbyFirmSlice';
import { exportConsultantDashboard } from '../../store/reducers/consultantSlice';
import { exportRegistrationDashboard } from '../../store/reducers/RegistrationSlice';
import { exportGlobalDashboard } from '../../store/reducers/globalSlice';
import { exportInstitutionDashboard } from '../../store/reducers/institutionSlice';
import { recentUpdates } from '../../features/shared/interfaces/whats-new.interface';

interface dashboardProps {
    type:
        | 'sectors'
        | 'organizations'
        | 'dpoh'
        | 'institutions'
        | 'committees'
        | 'global'
        | 'registration'
        | 'consultants'
        | 'firms'
        | 'issues'
        | 'subjects';
    modalOpen: boolean;
    handleClose: () => void;
    title: string;

    updates?: recentUpdates | null;
    overview?: entityOverviews | null;
    keywordMentions?: keywordMentions[] | null;
    recentLobbying?: entityLobbyingActivity[] | null;
    recentRegistrations?: entityRegistration[] | null;
    recentDeregistrations?: entityRegistration[] | null;
    mpMentions?: entityCluster | null;
    organizations?: entityCluster | null;
    institutions?: entityCluster | null;
    publicOfficeHolders?: entityCluster | null;
    subjects?: entityCluster | null;
    keyPhrases?: bumperSuperList[] | null;
    lobbyFirms?: entityCluster | null;
    dpohActivity?: entityActivity[] | null;
    registrations?: entityRegistration[] | null;
    deregistrations?: entityRegistration[] | null;
    lobbyingBreakdown?: entityLobbyingBreakdown | null;
    twoBreakdowns?: twoEntityLobbyingBreakdowns | null;
    memberships?: committeeMember[] | null;
    committee_meetings?: bumperSuperList[] | null;
    org_cluster?: entityCluster | null;
    sector_cluster?: entityCluster | null;
    dpoh_cluster?: entityCluster | null;
    subject_cluster?: entityCluster | null;
    firm_cluster?: entityCluster | null;
    orgActivity?: entityActivity[] | null;
    recentActivity?: entityLobbyingActivity[] | null;
    sectors?: entityCluster | null;
    keywordComposition?: issueInfo | null;
    keywordNarrative?: bumperSuperList[] | null;
    registrationInfo?: entityRegistration | null;
    registrationDpohActivity?: RegistrationDPOHActivity[] | null;
    exportLoading: boolean;
    exportFail: boolean;
    inactive?: boolean | null;
}

export const DashboardDownloadModal = ({
    type,
    modalOpen,
    handleClose,
    title,
    exportLoading,
    exportFail,
    updates,
    overview,
    keywordMentions,
    mpMentions,
    recentLobbying,
    recentRegistrations,
    recentDeregistrations,
    organizations,
    institutions,
    publicOfficeHolders,
    subjects,
    keyPhrases,
    lobbyFirms,
    dpohActivity,
    recentActivity,
    orgActivity,
    registrations,
    deregistrations,
    memberships,
    org_cluster,
    sector_cluster,
    dpoh_cluster,
    subject_cluster,
    firm_cluster,
    committee_meetings,
    lobbyingBreakdown,
    sectors,
    keywordComposition,
    keywordNarrative,
    twoBreakdowns,
    registrationInfo,
    registrationDpohActivity,
    inactive,
}: dashboardProps) => {
    const dispatch = useAppDispatch();
    const [includeUpdates, setIncludeUpdates] = useState(false);
    const [includeOverview, setIncludeOverview] = useState(false);
    const [includeRecentLobbying, setIncludeRecentLobbying] = useState(false);
    const [includeRecentRegistrations, setIncludeRecentRegistrations] = useState(false);
    const [includeRecentDeregistrations, setIncludeRecentDeregistrations] = useState(false);
    const [includeOrganizations, setIncludeOrganizations] = useState(false);
    const [includeInstitutions, setIncludeInstitutions] = useState(false);
    const [includePublicOfficeHolders, setIncludePublicOfficeHolders] = useState(false);
    const [includeSubjects, setIncludeSubjects] = useState(false);
    const [includeKeyPhrases, setIncludeKeyPhrases] = useState(false);
    const [includeLobbyFirms, setIncludeLobbyFirms] = useState(false);
    const [includeMpMentions, setIncludeMpMentions] = useState(false);

    const [includeLobbyingBreakdown, setIncludeLobbyingBreakdown] = useState(false);
    const [includeDpohActivity, setIncludeDpohActivity] = useState(false);
    const [includeRegistrations, setIncludeRegistrations] = useState(false);
    const [includeDeregistrations, setIncludeDeregistrations] = useState(false);

    const [includeMemberships, setIncludeMemberships] = useState(false);
    const [includeOrgCluster, setIncludeOrgCluster] = useState(false);
    const [includeSectorCluster, setIncludeSectorCluster] = useState(false);
    const [includeDPOHCluster, setIncludeDPOHCluster] = useState(false);
    const [includeSubjectCluster, setIncludeSubjectCluster] = useState(false);
    const [includeFirmCluster, setIncludeFirmCluster] = useState(false);
    const [includeCommitteeMeetings, setIncludeCommitteeMeetings] = useState(false);

    const [includeOrgActivity, setIncludeOrgActivity] = useState(false);
    const [includeRecentActivity, setIncludeRecentActivity] = useState(false);

    const [includeSectors, setIncludeSectors] = useState(false);

    const [includeKeywordComposition, setIncludeKeywordComposition] = useState(false);
    const [includeKeywordNarrative, setIncludeKeywordNarrative] = useState(false);
    const [includeTwoBreakdowns, setIncludeTwoBreakdowns] = useState(false);
    const [includeRegistrationInfo, setIncludeRegistrationInfo] = useState(false);

    const [includeRegistrationDpohActivity, setIncludeRegistrationDpohActivity] = useState(false);

    const [recentLobbyingRowCount, setRecentLobbyingRowCount] = useState(0);
    const [recentRegistrationsRowCount, setRecentRegistrationsRowCount] = useState(0);
    const [recentDeregistrationsRowCount, setRecentDeregistrationsRowCount] = useState(0);
    const [dpohActivityRowCount, setDpohActivityRowCount] = useState(0);
    const [registrationsRowCount, setRegistrationsRowCount] = useState(0);
    const [deregistrationsRowCount, setDeregistrationsRowCount] = useState(0);

    const [membershipsRowCount, setMembershipsRowCount] = useState(0);

    const [orgActivityRowCount, setOrgActivityRowCount] = useState(0);
    const [recentActivityRowCount, setRecentActivityRowCount] = useState(0);

    const [keywordNarrativeRowCount, setKeywordNarrativeRowCount] = useState(0);
    const [registrationDpohActivityRowCount, setRegistrationDpohActivityRowCount] = useState(0);

    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
        if (recentRegistrations && recentRegistrations.length > 0) {
            setRecentRegistrationsRowCount(Math.min(20, recentRegistrations.length));
        }
        if (recentDeregistrations && recentDeregistrations.length > 0) {
            setRecentDeregistrationsRowCount(Math.min(20, recentDeregistrations.length));
        }
        if (recentLobbying && recentLobbying.length > 0) {
            setRecentLobbyingRowCount(Math.min(25, recentLobbying.length));
        }
        if (dpohActivity && dpohActivity.length > 0) {
            setDpohActivityRowCount(Math.min(10, dpohActivity.length));
        }
        if (registrations && registrations.length > 0) {
            setRegistrationsRowCount(Math.min(10, registrations.length));
        }
        if (deregistrations && deregistrations.length > 0) {
            setDeregistrationsRowCount(Math.min(10, deregistrations.length));
        }
        if (memberships && memberships.length > 0) {
            setMembershipsRowCount(Math.min(10, memberships.length));
        }
        if (orgActivity && orgActivity.length > 0) {
            setOrgActivityRowCount(Math.min(10, orgActivity.length));
        }
        if (recentActivity && recentActivity.length > 0) {
            setRecentActivityRowCount(Math.min(10, recentActivity.length));
        }
        if (keywordNarrative && keywordNarrative.length > 0) {
            setKeywordNarrativeRowCount(Math.min(10, keywordNarrative.length));
        }
        if (registrationDpohActivity && registrationDpohActivity.length > 0) {
            setRegistrationDpohActivityRowCount(Math.min(10, registrationDpohActivity.length));
        }
    }, [
        recentRegistrations,
        recentDeregistrations,
        recentLobbying,
        organizations,
        institutions,
        publicOfficeHolders,
        subjects,
        lobbyFirms,
        dpohActivity,
        registrations,
        deregistrations,
        memberships,
        orgActivity,
        recentActivity,
        keywordNarrative,
        registrationDpohActivity,
    ]);

    const handleSelectAll = () => {
        setSelectAll(!selectAll);
        updates && setIncludeUpdates(!selectAll);
        overview && setIncludeOverview(!selectAll);
        recentRegistrations && setIncludeRecentRegistrations(!selectAll);
        recentDeregistrations && setIncludeRecentDeregistrations(!selectAll);
        recentLobbying && setIncludeRecentLobbying(!selectAll);
        organizations && setIncludeOrganizations(!selectAll);
        mpMentions && setIncludeMpMentions(!selectAll);
        institutions && setIncludeInstitutions(!selectAll);
        publicOfficeHolders && setIncludePublicOfficeHolders(!selectAll);
        subjects && setIncludeSubjects(!selectAll);
        keyPhrases && setIncludeKeyPhrases(!selectAll);
        lobbyFirms && setIncludeLobbyFirms(!selectAll);
        lobbyingBreakdown && setIncludeLobbyingBreakdown(!selectAll);
        dpohActivity && setIncludeDpohActivity(!selectAll);
        registrations && setIncludeRegistrations(!selectAll);
        deregistrations && setIncludeDeregistrations(!selectAll);
        memberships && setIncludeMemberships(!selectAll);
        committee_meetings && setIncludeCommitteeMeetings(!selectAll);
        org_cluster && setIncludeOrgCluster(!selectAll);
        sector_cluster && setIncludeSectorCluster(!selectAll);
        dpoh_cluster && setIncludeDPOHCluster(!selectAll);
        subject_cluster && setIncludeSubjectCluster(!selectAll);
        firm_cluster && setIncludeFirmCluster(!selectAll);
        orgActivity && setIncludeOrgActivity(!selectAll);
        recentActivity && setIncludeRecentActivity(!selectAll);
        sectors && setIncludeSectors(!selectAll);
        keywordComposition && setIncludeKeywordComposition(!selectAll);
        keywordNarrative && setIncludeKeywordNarrative(!selectAll);
        registrationInfo && setIncludeRegistrationInfo(!selectAll);
        twoBreakdowns && setIncludeTwoBreakdowns(!selectAll);
        registrationDpohActivity && setIncludeRegistrationDpohActivity(!selectAll);
    };

    const prepareDataForExport = (data: entityCluster, maxRows: number) => {
        return {
            top_movers: data.top_movers,
            top_orgs: data.top_orgs,
            top_four: data.top_four,
            isInstitutionType: data.isInstitutionType,
            movers_meta: data.movers_meta,
            numRows: maxRows,
        };
    };

    const handleExport = () => {
        if (type === 'sectors') {
            dispatch(
                exportSectorDashboard({
                    title,
                    updates: includeUpdates ? updates : null,
                    overview: includeOverview ? overview : null,
                    recentLobbying: includeRecentLobbying
                        ? recentLobbying?.slice(0, recentLobbyingRowCount)
                        : null,
                    recentRegistrations: includeRecentRegistrations
                        ? recentRegistrations?.slice(0, recentRegistrationsRowCount)
                        : null,
                    recentDeregistrations: includeRecentDeregistrations
                        ? recentDeregistrations?.slice(0, recentDeregistrationsRowCount)
                        : null,
                    organizations: includeOrganizations
                        ? prepareDataForExport(organizations!, 10)
                        : null,
                    institutions: includeInstitutions
                        ? prepareDataForExport(institutions!, 10)
                        : null,
                    publicOfficeHolders: includePublicOfficeHolders
                        ? prepareDataForExport(publicOfficeHolders!, 10)
                        : null,
                    subjects: includeSubjects ? prepareDataForExport(subjects!, 10) : null,
                    keyPhrases: includeKeyPhrases ? keyPhrases : null,
                    lobbyFirms: includeLobbyFirms ? prepareDataForExport(lobbyFirms!, 10) : null,
                })
            );
        } else if (type === 'organizations') {
            dispatch(
                exportOrganizationDashboard({
                    title,
                    overview: includeOverview ? overview : null,
                    recentLobbying: includeRecentLobbying
                        ? recentLobbying?.slice(0, recentLobbyingRowCount)
                        : null,
                    registrations: includeRegistrations
                        ? registrations?.slice(0, registrationsRowCount)
                        : null,
                    deregistrations: includeDeregistrations
                        ? deregistrations?.slice(0, deregistrationsRowCount)
                        : null,
                    dpohActivity: includeDpohActivity
                        ? dpohActivity?.slice(0, dpohActivityRowCount)
                        : null,
                    lobbyingBreakdown: includeLobbyingBreakdown ? lobbyingBreakdown : null,
                    inactive: inactive,
                })
            );
        } else if (type === 'firms') {
            dispatch(
                exportLobbyFirmDashboard({
                    title,
                    overview: includeOverview ? overview : null,
                    lobbyingBreakdown: includeLobbyingBreakdown ? lobbyingBreakdown : null,
                    registrations: includeRegistrations
                        ? registrations?.slice(0, registrationsRowCount)
                        : null,
                    deregistrations: includeDeregistrations
                        ? deregistrations?.slice(0, deregistrationsRowCount)
                        : null,
                })
            );
        } else if (type === 'consultants') {
            dispatch(
                exportConsultantDashboard({
                    title,
                    overview: includeOverview ? overview : null,
                    lobbyingBreakdown: includeLobbyingBreakdown ? lobbyingBreakdown : null,
                    registrations: includeRegistrations
                        ? registrations?.slice(0, registrationsRowCount)
                        : null,
                    deregistrations: includeDeregistrations
                        ? deregistrations?.slice(0, deregistrationsRowCount)
                        : null,
                })
            );
        } else if (type === 'committees') {
            dispatch(
                exportCommitteeDashboard({
                    title,
                    overview: includeOverview ? overview : null,
                    memberships: includeMemberships
                        ? memberships?.slice(0, membershipsRowCount)
                        : null,
                    recentLobbying: includeRecentLobbying
                        ? recentLobbying?.slice(0, recentLobbyingRowCount)
                        : null,
                    committee_meetings: includeCommitteeMeetings ? committee_meetings : null,
                    org_cluster: includeOrgCluster ? prepareDataForExport(org_cluster!, 10) : null,
                    sector_cluster: includeSectorCluster
                        ? prepareDataForExport(sector_cluster!, 10)
                        : null,
                    dpoh_cluster: includeDPOHCluster
                        ? prepareDataForExport(dpoh_cluster!, 10)
                        : null,
                    subject_cluster: includeSubjectCluster
                        ? prepareDataForExport(subject_cluster!, 10)
                        : null,
                    firm_cluster: includeFirmCluster
                        ? prepareDataForExport(firm_cluster!, 10)
                        : null,
                })
            );
        } else if (type === 'dpoh') {
            dispatch(
                exportDPOHDashboard({
                    title,
                    overview: includeOverview ? overview : null,
                    lobbyingBreakdown: includeLobbyingBreakdown ? lobbyingBreakdown : null,
                    recentActivity: includeRecentActivity
                        ? recentActivity?.slice(0, recentActivityRowCount)
                        : null,
                    orgActivity: includeOrgActivity
                        ? orgActivity?.slice(0, orgActivityRowCount)
                        : null,
                })
            );
        } else if (type === 'issues') {
            dispatch(
                exportIssueDashboard({
                    title,
                    updates: includeUpdates ? updates : null,
                    overview: includeOverview ? overview!.monthly : null,
                    keywordMentions: includeOverview ? keywordMentions : null,
                    keywordComposition: includeKeywordComposition ? keywordComposition : null,
                    recentLobbying: includeRecentLobbying
                        ? recentLobbying?.slice(0, recentLobbyingRowCount)
                        : null,
                    recentRegistrations: includeRecentRegistrations
                        ? recentRegistrations?.slice(0, recentRegistrationsRowCount)
                        : null,
                    recentDeregistrations: includeRecentDeregistrations
                        ? recentDeregistrations?.slice(0, recentDeregistrationsRowCount)
                        : null,
                    mpMentions: includeMpMentions ? prepareDataForExport(mpMentions!, 10) : null,
                    sectors: includeSectors ? prepareDataForExport(sectors!, 10) : null,
                    organizations: includeOrganizations
                        ? prepareDataForExport(organizations!, 10)
                        : null,
                    institutions: includeInstitutions
                        ? prepareDataForExport(institutions!, 10)
                        : null,
                    publicOfficeHolders: includePublicOfficeHolders
                        ? prepareDataForExport(publicOfficeHolders!, 10)
                        : null,
                    subjects: includeSubjects ? prepareDataForExport(subjects!, 10) : null,
                    lobbyFirms: includeLobbyFirms ? prepareDataForExport(lobbyFirms!, 10) : null,
                    keywordNarrative: includeKeywordNarrative
                        ? keywordNarrative!.slice(0, keywordNarrativeRowCount)
                        : null,
                })
            );
        } else if (type === 'subjects') {
            dispatch(
                exportSubjectDashboard({
                    title,
                    overview: includeOverview ? overview : null,
                    recentLobbying: includeRecentLobbying
                        ? recentLobbying?.slice(0, recentLobbyingRowCount)
                        : null,
                    recentRegistrations: includeRecentRegistrations
                        ? recentRegistrations?.slice(0, recentRegistrationsRowCount)
                        : null,
                    recentDeregistrations: includeRecentDeregistrations
                        ? recentDeregistrations?.slice(0, recentDeregistrationsRowCount)
                        : null,
                    sectors: includeSectors ? prepareDataForExport(sectors!, 10) : null,
                    organizations: includeOrganizations
                        ? prepareDataForExport(organizations!, 10)
                        : null,
                    institutions: includeInstitutions
                        ? prepareDataForExport(institutions!, 10)
                        : null,
                    publicOfficeHolders: includePublicOfficeHolders
                        ? prepareDataForExport(publicOfficeHolders!, 10)
                        : null,
                    lobbyFirms: includeLobbyFirms ? prepareDataForExport(lobbyFirms!, 10) : null,
                })
            );
        } else if (type === 'institutions') {
            dispatch(
                exportInstitutionDashboard({
                    title,
                    updates: includeUpdates ? updates : null,
                    overview: includeOverview ? overview : null,
                    recentLobbying: includeRecentLobbying
                        ? recentLobbying?.slice(0, recentLobbyingRowCount)
                        : null,
                    organizations: includeOrganizations
                        ? prepareDataForExport(organizations!, 10)
                        : null,
                    sectors: includeSectors ? prepareDataForExport(sectors!, 10) : null,
                    publicOfficeHolders: includePublicOfficeHolders
                        ? prepareDataForExport(publicOfficeHolders!, 10)
                        : null,
                    subjects: includeSubjects ? prepareDataForExport(subjects!, 10) : null,
                    keyPhrases: includeKeyPhrases ? keyPhrases : null,
                    lobbyFirms: includeLobbyFirms ? prepareDataForExport(lobbyFirms!, 10) : null,
                })
            );
        } else if (type === 'registration') {
            dispatch(
                exportRegistrationDashboard({
                    title,
                    registrationInfo: includeRegistrationInfo ? registrationInfo : null,
                    overview: includeOverview ? overview : null,
                    recentLobbying: includeRecentLobbying
                        ? recentLobbying?.slice(0, recentLobbyingRowCount)
                        : null,
                    registrationDpohActivity: includeRegistrationDpohActivity
                        ? registrationDpohActivity?.slice(0, registrationDpohActivityRowCount)
                        : null,
                    twoBreakdowns: includeTwoBreakdowns ? twoBreakdowns : null,
                })
            );
        } else if (type === 'global') {
            dispatch(
                exportGlobalDashboard({
                    title,
                    overview: includeOverview ? overview : null,
                    subject_cluster: includeSubjectCluster
                        ? prepareDataForExport(subject_cluster!, 10)
                        : null,
                    sector_cluster: includeSectorCluster
                        ? prepareDataForExport(sector_cluster!, 10)
                        : null,
                    org_cluster: includeOrgCluster ? prepareDataForExport(org_cluster!, 10) : null,
                    institutions: includeInstitutions
                        ? prepareDataForExport(institutions!, 10)
                        : null,
                    dpoh_cluster: includeDPOHCluster
                        ? prepareDataForExport(dpoh_cluster!, 10)
                        : null,
                    firm_cluster: includeFirmCluster
                        ? prepareDataForExport(firm_cluster!, 10)
                        : null,
                })
            );
        }
    };

    return (
        <Modal title="Save Dashboard PDF" width={40} open={modalOpen} onClose={handleClose}>
            <div className="flex flex-col max-h-[90vh] overflow-y-auto">
                <div className="flex lg:flex-row flex-col justify-between gap-2 mb-4">
                    <div className="mr-3">Select which exhibits to include in the PDF</div>
                    <div
                        className="text-blue-400 hover:underline hover:cursor-pointer underline-offset-2"
                        onClick={handleSelectAll}
                    >
                        Select All
                    </div>
                </div>
                {registrationInfo && (
                    <ExportExhibitSelector
                        labelText={'Registration Info'}
                        includeSelection={includeRegistrationInfo}
                        handleChangeSelection={setIncludeRegistrationInfo}
                        hasRowCount={false}
                    />
                )}
                {keywordComposition && (
                    <ExportExhibitSelector
                        labelText={'Keyword Composition'}
                        includeSelection={includeKeywordComposition}
                        handleChangeSelection={setIncludeKeywordComposition}
                        hasRowCount={false}
                    />
                )}
                {updates && (
                    <ExportExhibitSelector
                        labelText={'Recent Updates'}
                        includeSelection={includeUpdates}
                        handleChangeSelection={setIncludeUpdates}
                        hasRowCount={false}
                    />
                )}
                {overview && (
                    <ExportExhibitSelector
                        labelText={type === 'issues' ? 'Issue Salience' : 'Overview'}
                        includeSelection={includeOverview}
                        handleChangeSelection={setIncludeOverview}
                        hasRowCount={false}
                    />
                )}
                {keywordNarrative && (
                    <ExportExhibitSelector
                        labelText={'Mentions of Issue Keywords'}
                        includeSelection={includeKeywordNarrative}
                        handleChangeSelection={setIncludeKeywordNarrative}
                        hasRowCount={true}
                        selectedRowCount={keywordNarrativeRowCount}
                        handleChangeRowCount={setKeywordNarrativeRowCount}
                        dataLength={keywordNarrative.length}
                    />
                )}
                {recentLobbying && recentLobbying.length ? (
                    <ExportExhibitSelector
                        labelText={
                            type === 'issues' ? 'Recent Lobbying on Issue' : 'Recent Lobbying'
                        }
                        includeSelection={includeRecentLobbying}
                        handleChangeSelection={setIncludeRecentLobbying}
                        hasRowCount={true}
                        selectedRowCount={recentLobbyingRowCount}
                        handleChangeRowCount={setRecentLobbyingRowCount}
                        dataLength={recentLobbying.length}
                    />
                ) : (
                    <></>
                )}
                {recentRegistrations && recentRegistrations.length ? (
                    <ExportExhibitSelector
                        labelText={
                            type === 'issues' ? 'Recent Lobbying Registrations' : 'Registrations'
                        }
                        includeSelection={includeRecentRegistrations}
                        handleChangeSelection={setIncludeRecentRegistrations}
                        hasRowCount={true}
                        selectedRowCount={recentRegistrationsRowCount}
                        handleChangeRowCount={setRecentRegistrationsRowCount}
                        dataLength={recentRegistrations.length}
                    />
                ) : (
                    <></>
                )}
                {recentDeregistrations && recentDeregistrations.length ? (
                    <ExportExhibitSelector
                        labelText={
                            type === 'issues'
                                ? 'Recent Lobbying Deregistrations'
                                : 'Deregistrations'
                        }
                        includeSelection={includeRecentDeregistrations}
                        handleChangeSelection={setIncludeRecentDeregistrations}
                        hasRowCount={true}
                        selectedRowCount={recentDeregistrationsRowCount}
                        handleChangeRowCount={setRecentDeregistrationsRowCount}
                        dataLength={recentDeregistrations.length}
                    />
                ) : (
                    <></>
                )}
                {mpMentions && (
                    <ExportExhibitSelector
                        labelText={'MP Mentions of issue'}
                        includeSelection={includeMpMentions}
                        handleChangeSelection={setIncludeMpMentions}
                        hasRowCount={false}
                    />
                )}
                {sectors && (
                    <ExportExhibitSelector
                        labelText={type === 'issues' ? 'Sectors Lobbying on Issue' : 'Sectors'}
                        includeSelection={includeSectors}
                        handleChangeSelection={setIncludeSectors}
                        hasRowCount={false}
                    />
                )}
                {organizations && (
                    <ExportExhibitSelector
                        labelText={
                            type === 'issues' ? 'Organizations Lobbying on Issue' : 'Organizations'
                        }
                        includeSelection={includeOrganizations}
                        handleChangeSelection={setIncludeOrganizations}
                        hasRowCount={false}
                    />
                )}
                {institutions && (
                    <ExportExhibitSelector
                        labelText={
                            type === 'global'
                                ? 'Government Institutions'
                                : type === 'issues'
                                ? 'Institutions Lobbied on Issue'
                                : 'Institutions'
                        }
                        includeSelection={includeInstitutions}
                        handleChangeSelection={setIncludeInstitutions}
                        hasRowCount={false}
                    />
                )}
                {publicOfficeHolders && (
                    <ExportExhibitSelector
                        labelText={
                            type === 'issues' ? 'DPOHs Lobbied on Issue' : 'Public Office Holders'
                        }
                        includeSelection={includePublicOfficeHolders}
                        handleChangeSelection={setIncludePublicOfficeHolders}
                        hasRowCount={false}
                    />
                )}
                {subjects && (
                    <ExportExhibitSelector
                        labelText={
                            type === 'issues' ? 'Subjects of Lobbying Communications' : 'Subjects'
                        }
                        includeSelection={includeSubjects}
                        handleChangeSelection={setIncludeSubjects}
                        hasRowCount={false}
                    />
                )}
                {keyPhrases && title !== 'House of Commons' && (
                    <ExportExhibitSelector
                        labelText={'Key Phrases'}
                        includeSelection={includeKeyPhrases}
                        handleChangeSelection={setIncludeKeyPhrases}
                        hasRowCount={false}
                    />
                )}
                {lobbyFirms && (
                    <ExportExhibitSelector
                        labelText={'Lobby Firms'}
                        includeSelection={includeLobbyFirms}
                        handleChangeSelection={setIncludeLobbyFirms}
                        hasRowCount={false}
                    />
                )}
                {lobbyingBreakdown && (
                    <ExportExhibitSelector
                        labelText={'Lobbying Breakdown'}
                        includeSelection={includeLobbyingBreakdown}
                        handleChangeSelection={setIncludeLobbyingBreakdown}
                        hasRowCount={false}
                    />
                )}
                {dpohActivity && (
                    <ExportExhibitSelector
                        labelText={"DPOH's Lobbied"}
                        includeSelection={includeDpohActivity}
                        handleChangeSelection={setIncludeDpohActivity}
                        hasRowCount={true}
                        selectedRowCount={dpohActivityRowCount}
                        handleChangeRowCount={setDpohActivityRowCount}
                        dataLength={dpohActivity.length}
                    />
                )}
                {registrationDpohActivity && (
                    <ExportExhibitSelector
                        labelText={"DPOH's Lobbied"}
                        includeSelection={includeRegistrationDpohActivity}
                        handleChangeSelection={setIncludeRegistrationDpohActivity}
                        hasRowCount={true}
                        selectedRowCount={registrationDpohActivityRowCount}
                        handleChangeRowCount={setRegistrationDpohActivityRowCount}
                        dataLength={registrationDpohActivity.length}
                    />
                )}
                {registrations && registrations.length > 0 && (
                    <ExportExhibitSelector
                        labelText={'Registrations'}
                        includeSelection={includeRegistrations}
                        handleChangeSelection={setIncludeRegistrations}
                        hasRowCount={true}
                        selectedRowCount={registrationsRowCount}
                        handleChangeRowCount={setRegistrationsRowCount}
                        dataLength={registrations.length}
                    />
                )}
                {deregistrations && deregistrations.length > 0 && (
                    <ExportExhibitSelector
                        labelText={'Deregistrations'}
                        includeSelection={includeDeregistrations}
                        handleChangeSelection={setIncludeDeregistrations}
                        hasRowCount={true}
                        selectedRowCount={deregistrationsRowCount}
                        handleChangeRowCount={setDeregistrationsRowCount}
                        dataLength={deregistrations.length}
                    />
                )}
                {memberships && (
                    <ExportExhibitSelector
                        labelText={'Memberships'}
                        includeSelection={includeMemberships}
                        handleChangeSelection={setIncludeMemberships}
                        hasRowCount={true}
                        selectedRowCount={membershipsRowCount}
                        handleChangeRowCount={setMembershipsRowCount}
                        dataLength={memberships.length}
                    />
                )}
                {committee_meetings && (
                    <ExportExhibitSelector
                        labelText={'Committee Meetings'}
                        includeSelection={includeCommitteeMeetings}
                        handleChangeSelection={setIncludeCommitteeMeetings}
                        hasRowCount={false}
                    />
                )}
                {org_cluster && (
                    <ExportExhibitSelector
                        labelText={type === 'global' ? 'Organizations' : 'Organizations Lobbying'}
                        includeSelection={includeOrgCluster}
                        handleChangeSelection={setIncludeOrgCluster}
                        hasRowCount={false}
                    />
                )}
                {sector_cluster && (
                    <ExportExhibitSelector
                        labelText={type === 'global' ? 'Sectors' : 'Sectors Lobbying'}
                        includeSelection={includeSectorCluster}
                        handleChangeSelection={setIncludeSectorCluster}
                        hasRowCount={false}
                    />
                )}
                {dpoh_cluster && (
                    <ExportExhibitSelector
                        labelText={
                            type === 'global'
                                ? 'Designated Public Office Holders'
                                : 'Comm. Members Lobbied'
                        }
                        includeSelection={includeDPOHCluster}
                        handleChangeSelection={setIncludeDPOHCluster}
                        hasRowCount={false}
                    />
                )}
                {subject_cluster && (
                    <ExportExhibitSelector
                        labelText={type === 'global' ? 'Subjects' : 'Lobbying Subjects'}
                        includeSelection={includeSubjectCluster}
                        handleChangeSelection={setIncludeSubjectCluster}
                        hasRowCount={false}
                    />
                )}
                {firm_cluster && (
                    <ExportExhibitSelector
                        labelText={'Lobby Firms'}
                        includeSelection={includeFirmCluster}
                        handleChangeSelection={setIncludeFirmCluster}
                        hasRowCount={false}
                    />
                )}
                {recentActivity && (
                    <ExportExhibitSelector
                        labelText={'Recent Lobbying Comms.'}
                        includeSelection={includeRecentActivity}
                        handleChangeSelection={setIncludeRecentActivity}
                        hasRowCount={true}
                        selectedRowCount={recentActivityRowCount}
                        handleChangeRowCount={setRecentActivityRowCount}
                        dataLength={recentActivity.length}
                    />
                )}
                {orgActivity && (
                    <ExportExhibitSelector
                        labelText={'Organizations Lobbying'}
                        includeSelection={includeOrgActivity}
                        handleChangeSelection={setIncludeOrgActivity}
                        hasRowCount={true}
                        selectedRowCount={orgActivityRowCount}
                        handleChangeRowCount={setOrgActivityRowCount}
                        dataLength={orgActivity.length}
                    />
                )}
                {twoBreakdowns && (
                    <ExportExhibitSelector
                        labelText={'Lobbying Communications'}
                        includeSelection={includeTwoBreakdowns}
                        handleChangeSelection={setIncludeTwoBreakdowns}
                        hasRowCount={false}
                    />
                )}
                <div className="mt-5 flex place-content-center">
                    {exportLoading ? (
                        <LoadSpinner />
                    ) : (
                        <div className="w-full">
                            <Button variant="contained" fullWidth onClick={handleExport}>
                                Download
                            </Button>

                            {exportFail && (
                                <div className="mt-3 text-red-600">
                                    Issue generating report, please try again.
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};
