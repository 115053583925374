import axios from 'axios';
import {
    entityLobbyingActivity,
    entityOrg,
    entityOverview,
    entityOverviewRefined,
    entityOverviews,
    entityRegistration,
} from '../interfaces/generic-entity.interface';
import moment from 'moment';
import {
    RegistrationActivityCombo,
    RegistrationDPOHActivity,
} from '../interfaces/organization-entity.interface';
import { overviewFormatter } from '../helpers/overview_formatter';
import {
    DPOHLinkFormatter,
    consultantLinkFormatter,
    institutionLinkFormatter,
    lobbyFirmLinkFormatter,
} from '../helpers/internal_routing';

// Base URL for accessing the sector API
const baseURL = process.env.REACT_APP_API_BASE_URL;

// Fetch overview of a given registration
const fetchRegistrationOverview = async (reg_id: number) => {
    const date = moment().format('YYYY-MM-DD');
    const year = date?.split('-')[0];
    const month = date?.split('-')[1];

    const yearly_response = await axios.get(
        `${baseURL}/registration/${encodeURIComponent(reg_id)}/overview?monthly=false`
    );
    const yearly_data: entityOverviewRefined[] = yearly_response.data.regOverview
        .filter((entry: any) => +entry.year <= +year)
        .map((entry: entityOverview) => {
            return {
                ...entry,
                'Lobbying Reports': entry.count,
                'by Consultants': entry.external,
                'of Ministers or DMs': entry.ministers,
                'Institutions Lobbied': entry.institutions,
            };
        });

    const inverted_yearly = overviewFormatter(yearly_data, [
        'Lobbying Reports',
        'by Consultants',
        'of Ministers or DMs',
        'Institutions Lobbied',
    ]);

    const monthly_response = await axios.get(
        `${baseURL}/registration/${encodeURIComponent(reg_id)}/overview?monthly=true`
    );

    const monthly_data: entityOverview[] = monthly_response.data.regOverview
        .filter((entry: any) => {
            return (
                +[entry.year, (entry.month + '').padStart(2, '0')].join('') <=
                +[year, month].join('')
            );
        })
        .slice(0, 18);

    const overview: entityOverviews = {
        yearly: inverted_yearly,
        monthly: monthly_data,
    };

    return overview;
};

// Fetch info of a given registration
const fetchRegistrationInfo = async (reg_id: number) => {
    fetchRegistrationOverview(reg_id);
    const response = await axios.get(`${baseURL}/registration/${encodeURIComponent(reg_id)}/info`);
    const data: entityRegistration[] = response.data.registrationInfo;
    const converted_data: entityRegistration[] = data.map((entry: entityRegistration) => {
        return {
            ...entry,
            date: moment.utc(entry.date).format('YYYY-MM-DD'),
            organization: entry.client,
            lobby_firm:
                entry.lobby_firm === null || entry.lobby_firm === entry.client
                    ? 'In-House'
                    : entry.lobby_firm,
            consultant:
                entry.consultant === null || entry.consultant === entry.client
                    ? 'In-House'
                    : entry.consultant,
            history: entry.history ? 'Renewed' : 'New',
            link: `https://lobbycanada.gc.ca/app/secure/ocl/lrs/do/vwRg?cno=${entry.corp}&regId=${entry.registration}#regStart`,
        };
    });
    return converted_data[0];
};

// Fetch activity of a given registration
const fetchRegistrationActivity = async (reg_id: number, date: string) => {
    const response = await axios.get(
        `${baseURL}/registration/${encodeURIComponent(reg_id)}/activity?date=${date}`
    );
    const comms_data: entityLobbyingActivity[] = response.data.registrationActivity;
    const converted_comms_data: entityLobbyingActivity[] = comms_data.map((entry) => {
        return {
            ...entry,
            'Prior Comms (36M)': entry.three_years_prior || 0,
            date: moment.utc(entry.date).format('YYYY-MM-DD'),
            organization: entry.client,
            dpoh:
                entry.institution_id && entry.institution
                    ? DPOHLinkFormatter(entry.institution_id, entry.institution, entry.dpoh)
                    : entry.dpoh,
            institution:
                entry.institution_id && entry.institution
                    ? institutionLinkFormatter(entry.institution_id, entry.institution)
                    : entry.institution,
            lobby_firm:
                entry.lobby_firm === null || entry.consultant === null
                    ? 'In-House'
                    : lobbyFirmLinkFormatter(entry.lobby_firm),
            consultant:
                entry.lobby_firm === null
                    ? 'In-House'
                    : consultantLinkFormatter(entry.lobby_firm, entry.consultant),
            link: `https://lobbycanada.gc.ca/app/secure/ocl/lrs/do/cmmLgPblcVw?comlogId=${entry.comm}`,
        };
    });

    const dpoh_key = new Map();
    comms_data.forEach((comm: entityLobbyingActivity) => {
        if (!dpoh_key.get(comm.dpoh + '#' + comm.institution + '#' + comm.institution_id))
            dpoh_key.set(
                comm.dpoh + '#' + comm.institution + '#' + comm.institution_id,
                comm.title
            );
    });

    const converted_dpoh_data: RegistrationDPOHActivity[] = Array.from(dpoh_key.keys()).map(
        (DPOH: any) => {
            let dpoh_info = {
                name: DPOH.split('#')[0],
                institution: DPOH.split('#')[1],
                institution_id: DPOH.split('#')[2],
                title: dpoh_key.get(DPOH),
            };
            let comms = converted_comms_data.filter(
                (entry: entityLobbyingActivity) =>
                    entry.dpoh === dpoh_info.name && entry.institution === dpoh_info.institution
            );
            return {
                ...DPOH,
                name:
                    dpoh_info.institution_id && dpoh_info.institution
                        ? DPOHLinkFormatter(
                              dpoh_info.institution_id,
                              dpoh_info.institution,
                              dpoh_info.name
                          )
                        : dpoh_info.name,
                title: dpoh_info.title,
                institution:
                    dpoh_info.institution_id && dpoh_info.institution
                        ? institutionLinkFormatter(dpoh_info.institution_id, dpoh_info.institution)
                        : dpoh_info.institution,
                'prior_comms_(12M)': comms.length,
                'percent_comms_(12M)':
                    Math.round((comms.length / converted_comms_data.length) * 100 * 100) / 100,
            };
        }
    );

    const return_val: RegistrationActivityCombo = {
        activity: converted_comms_data,
        dpoh_activity: converted_dpoh_data,
    };

    return return_val;
};

// Fetch top institutions lobbied in a given registration
const fetchRegistrationTopInstitutions = async (reg_id: number, date: string) => {
    const response = await axios.get(
        `${baseURL}/registration/${encodeURIComponent(reg_id)}/topinstitutions?date=${date}`
    );
    const data: entityOrg[] = response.data.registrationTopInstitutions;

    // Count the total number of comms for institutions outside of the top 6
    var other_count = 0;
    if (data.length > 6)
        other_count = data
            .slice(6)
            .map((item) => item.count)
            .reduce((sum, next) => sum + next);

    const top_six = data.slice(0, 6);
    if (other_count > 0) top_six.push({ name: 'Other', code: 'Other', count: other_count });

    return top_six;
};

// Fetch top subjects lobbied in a given registration
const fetchRegistrationTopSubjects = async (reg_id: number, date: string) => {
    const response = await axios.get(
        `${baseURL}/registration/${encodeURIComponent(reg_id)}/topsubjects?date=${date}`
    );
    const data: entityOrg[] = response.data.registrationTopSubjects;

    // Count the total number of comms for institutions outside of the top 6
    var other_count = 0;
    if (data.length > 6)
        other_count = data
            .slice(6)
            .map((item) => item.count)
            .reduce((sum, next) => sum + next);

    const top_six = data.slice(0, 6);
    if (other_count > 0) top_six.push({ name: 'Other', code: 'Other', count: other_count });

    return top_six;
};

export const registrationAPI = {
    fetchRegistrationOverview,
    fetchRegistrationInfo,
    fetchRegistrationActivity,
    fetchRegistrationTopInstitutions,
    fetchRegistrationTopSubjects,
};
