import React, { useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Breadcrumbs,
    Button,
    ButtonGroup,
    Fade,
} from '@mui/material';
import { Link, useLocation, useParams } from 'react-router-dom';
import { replace_underscores_capitalize } from '../../helpers/string_formatters';
import Arrow from '@mui/icons-material/ArrowUpwardRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import '../../styles/flip_anim.css';
import Grow from '@mui/material/Grow';

import { entityListExtra, entityListSimple } from '../../interfaces/generic-entity.interface';

import { months } from '../../constants/date_time';

import EntityListSearch from './EntityListSearch';
import LoadingList from '../../components/generic-dashboards/loading/LoadingList';
import { Selection } from '../../store/reducers/customSelectionSlice';
import moment from 'moment';
import LoadPage from '../misc/LoadPage';
// Title and subtitle are optional components with defaults specified in function signature below.
interface entityListPageProps {
    type:
        | 'sectors'
        | 'institutions'
        | 'DPOHs'
        | 'MPs'
        | 'committees'
        | 'subjects'
        | 'issues'
        | 'organizations'
        | 'inactive organizations'
        | 'firms'
        | 'consultants';
    list: entityListExtra[];
    subList?: entityListSimple[];
    selections?: Selection[];
    loading?: boolean;
}

const EntityListPage = ({
    type,
    list,
    subList,
    selections = [],
    loading = false,
}: entityListPageProps) => {
    const curr_date = moment();

    curr_date.subtract(1, 'months');
    const last_month = months[curr_date.month()].slice(0, 3);

    curr_date.subtract(1, 'months');
    const two_months_ago = months[curr_date.month()].slice(0, 3);

    curr_date.subtract(1, 'months');
    const three_months_ago = months[curr_date.month()].slice(0, 3);

    const headers = [
        three_months_ago.slice(0, 3),
        two_months_ago.slice(0, 3),
        '+/-',
        '%',
        last_month.slice(0, 3),
        '12M',
        ...(type === 'firms' || type === 'consultants' ? ['registrations'] : []),
    ];

    const inactive_org_headers = ['12M', '36M'];

    const pill = `${
        type === 'issues'
            ? 'lg:col-span-3 col-span-3'
            : type === 'firms' || type === 'consultants'
            ? 'lg:col-span-2 col-span-3'
            : 'lg:col-span-2 col-span-3'
    } text-sm lg:bg-opacity-0 bg-slate-50 rounded-xl p-1 m-1 flex flex-row lg:justify-end justify-between`;

    const key_header_map = new Map();
    key_header_map.set(type, 'title');
    key_header_map.set(three_months_ago.slice(0, 3), 'two_months_ago');
    key_header_map.set(two_months_ago.slice(0, 3), 'last_month');
    key_header_map.set('+/-', 'change_abs');
    key_header_map.set('%', 'change_rel');
    key_header_map.set(last_month.slice(0, 3), 'mtd');
    key_header_map.set('12M', 'ttm');
    key_header_map.set('36M', 't36m');
    key_header_map.set('registrations', 'num_active_registrations');

    // Get the code and name of the current sector
    const url_params = useParams();
    const location = useLocation();

    const parent_code = url_params.entityId !== undefined ? url_params.entityId : '';
    const parent_name: string =
        new URLSearchParams(location.search).get(
            type === 'organizations' || type === 'inactive organizations'
                ? 'sector'
                : type === 'consultants'
                ? 'firm'
                : type === 'issues'
                ? 'subject'
                : type === 'MPs'
                ? 'committee'
                : 'institution'
        ) || '';

    const [expanded, setExpanded] = useState<boolean>(false);

    const [orderBy, setOrderBy] = useState<string>('ttm');
    const [order, setOrder] = useState<'asc' | 'desc'>('desc');

    const handleSort = (property: any) => {
        const prop = key_header_map.get(property);
        const isAsc = orderBy === prop && order === 'asc';
        setOrderBy(prop);
        setOrder(isAsc ? 'desc' : 'asc');
    };

    const sorted_list = [...list].sort((a: any, b: any) => {
        // if element is in selected options, move to top
        if (selections.find((selection) => selection.title === a.title)) {
            if (selections.find((selection) => selection.title === b.title)) {
                if (order === 'asc') {
                    if (orderBy === 'title') return a[orderBy] > b[orderBy] ? 1 : -1;
                    else return +a[orderBy] > +b[orderBy] ? 1 : -1;
                } else {
                    if (orderBy === 'title') return a[orderBy] < b[orderBy] ? 1 : -1;
                    else return +a[orderBy] < +b[orderBy] ? 1 : -1;
                }
            }
            return -1;
        } else if (selections.find((selection) => selection.title === b.title)) {
            return 1;
        }

        // otherwise sort normally
        if (order === 'asc') {
            if (orderBy === 'title') return a[orderBy] > b[orderBy] ? 1 : -1;
            else return +a[orderBy] > +b[orderBy] ? 1 : -1;
        } else {
            if (orderBy === 'title') return a[orderBy] < b[orderBy] ? 1 : -1;
            else return +a[orderBy] < +b[orderBy] ? 1 : -1;
        }
    });

    return loading ? (
        <LoadPage />
    ) : (
        <Fade in={true}>
            <div className="lg:py-32 py-20 grid grid-cols-12">
                <Breadcrumbs className="col-start-2 col-end-12" separator=">">
                    <Link to="/" className="hover:underline">
                        Lobby<b>IQ</b>
                    </Link>
                    {type === 'organizations' && (
                        <Link to={`/sectors`} className="hover:underline">
                            Sectors
                        </Link>
                    )}
                    {type === 'organizations' && (
                        <Link
                            to={`/sectors/${parent_code}?name=${parent_name}`}
                            className="hover:underline"
                        >
                            {parent_name}
                        </Link>
                    )}
                    {type === 'inactive organizations' && (
                        <Link to={`/sectors`} className="hover:underline">
                            Sectors
                        </Link>
                    )}
                    {type === 'inactive organizations' && (
                        <Link
                            to={`/sectors/${parent_code}?name=${parent_name}`}
                            className="hover:underline"
                        >
                            {parent_name}
                        </Link>
                    )}
                    {type === 'consultants' && (
                        <Link to={`/firms`} className="hover:underline">
                            Firms
                        </Link>
                    )}
                    {type === 'consultants' && (
                        <Link
                            to={`/firms/${parent_code}?name=${parent_name}`}
                            className="hover:underline"
                        >
                            {parent_name}
                        </Link>
                    )}
                    {type === 'issues' && parent_name !== '' && (
                        <Link to={`/subjects`} className="hover:underline">
                            Subjects
                        </Link>
                    )}
                    {type === 'issues' && parent_name !== '' && (
                        <Link
                            to={`/subjects/${parent_code}?name=${parent_name}`}
                            className="hover:underline"
                        >
                            {parent_name}
                        </Link>
                    )}
                    {type === 'DPOHs' && (
                        <Link to={`/institutions`} className="hover:underline">
                            Institutions
                        </Link>
                    )}
                    {type === 'DPOHs' && (
                        <Link
                            to={`/institutions/${parent_code}?name=${parent_name}`}
                            className="hover:underline"
                        >
                            {parent_name}
                        </Link>
                    )}
                    {type === 'MPs' && (
                        <Link to={`/committees`} className="hover:underline">
                            Parliamentary Committees
                        </Link>
                    )}
                    {type === 'MPs' && (
                        <Link
                            to={`/committees/${parent_code}?name=${parent_name}`}
                            className="hover:underline"
                        >
                            {parent_name}
                        </Link>
                    )}
                    <div>{replace_underscores_capitalize(type)}</div>
                </Breadcrumbs>

                <div className="lg:mt-8 mt-6 col-start-2 col-end-12 flex lg:flex-row flex-col justify-between lg:items-start">
                    <div className="text-md lg:text-xl">
                        Browse{' '}
                        {type === 'committees'
                            ? 'Parliamentary Committees'
                            : replace_underscores_capitalize(type)}
                        {type === 'organizations' ? ' From ' : ''}
                        {type === 'organizations' ? (
                            <Link
                                className="text-blue-400 font-medium hover:underline"
                                to={`/sectors/${parent_code}?name=${encodeURIComponent(
                                    parent_name
                                )}`}
                            >
                                {parent_name}
                            </Link>
                        ) : (
                            ''
                        )}
                        {type === 'inactive organizations' ? ' From ' : ''}
                        {type === 'inactive organizations' ? (
                            <Link
                                className="text-blue-400 font-medium hover:underline"
                                to={`/sectors/${parent_code}?name=${encodeURIComponent(
                                    parent_name
                                )}`}
                            >
                                {parent_name}
                            </Link>
                        ) : (
                            ''
                        )}
                        {type === 'consultants' && ' From '}
                        {type === 'consultants' && (
                            <Link
                                className="text-blue-400 font-medium hover:underline"
                                to={`/firms/${parent_code}?name=${encodeURIComponent(parent_name)}`}
                            >
                                {parent_name}
                            </Link>
                        )}
                        {type === 'DPOHs' && ' From '}
                        {type === 'DPOHs' && (
                            <Link
                                className="text-blue-400 font-medium hover:underline"
                                to={`/institutions/${parent_code}?name=${encodeURIComponent(
                                    parent_name
                                )}`}
                            >
                                {parent_name}
                            </Link>
                        )}
                        {type === 'MPs' && ' From '}
                        {type === 'MPs' && (
                            <Link
                                className="text-blue-400 font-medium hover:underline"
                                to={`/committees/${parent_code}?name=${encodeURIComponent(
                                    parent_name
                                )}`}
                            >
                                {parent_name}
                            </Link>
                        )}
                    </div>
                    <div
                        className={
                            ['organizations', 'consultants'].includes(type)
                                ? 'hidden'
                                : 'hidden lg:flex'
                        }
                    >
                        <ButtonGroup
                            size="small"
                            color={
                                type === 'sectors'
                                    ? 'success'
                                    : type === 'institutions'
                                    ? 'info'
                                    : type === 'committees'
                                    ? 'secondary'
                                    : type === 'firms' || type === 'consultants'
                                    ? 'primary'
                                    : type === 'subjects'
                                    ? 'warning'
                                    : 'error'
                            }
                        >
                            <Button
                                disableElevation
                                component={Link}
                                to="/sectors"
                                variant={type === 'sectors' ? 'contained' : 'outlined'}
                            >
                                Sectors
                            </Button>
                            <Button
                                disableElevation
                                component={Link}
                                to="/institutions"
                                variant={type === 'institutions' ? 'contained' : 'outlined'}
                            >
                                Institutions
                            </Button>
                            <Button
                                disableElevation
                                component={Link}
                                to="/committees"
                                variant={type === 'committees' ? 'contained' : 'outlined'}
                            >
                                Parliamentary Committees
                            </Button>
                            <Button
                                disableElevation
                                component={Link}
                                to="/subjects"
                                variant={type === 'subjects' ? 'contained' : 'outlined'}
                            >
                                Subjects
                            </Button>
                            <Button
                                disableElevation
                                component={Link}
                                to="/issues"
                                variant={type === 'issues' ? 'contained' : 'outlined'}
                            >
                                Issues
                            </Button>
                            <Button
                                disableElevation
                                component={Link}
                                to="/firms"
                                variant={type === 'firms' ? 'contained' : 'outlined'}
                            >
                                Lobby Firms
                            </Button>
                        </ButtonGroup>
                    </div>
                </div>

                <hr className="col-start-2 col-end-12 h-0.5 my-3 bg-slate-100 rounded" />

                <div className="col-start-2 col-end-12 shadow-sm rounded-md">
                    <Accordion
                        expanded={expanded}
                        onChange={() => setExpanded(!expanded)}
                        elevation={0}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>Search</AccordionSummary>
                        <AccordionDetails>
                            <EntityListSearch
                                type={type}
                                list={sorted_list}
                                subList={subList}
                                embedded
                                parentCode={parent_code}
                                parentName={parent_name}
                            />
                        </AccordionDetails>
                    </Accordion>
                </div>

                <div className="mt-8 col-start-2 col-end-12 grid grid-cols-12 lg:gap-0 gap-3 lg:mb-0 mb-6">
                    <div className="col-span-12 mb-2 flex flex-row justify-between items-center px-2">
                        <div className="text-slate-500">
                            {sorted_list.length} {type}
                        </div>
                        <div className="text-slate-500">
                            {type === 'issues' ? '' : 'Number of communication reports'}
                        </div>
                    </div>

                    <button
                        className={
                            'flex flex-row items-center lg:justify-start justify-between bg-white lg:bg-slate-50 rounded-xl lg:shadow-none shadow-sm group hover:text-gray-900 lg:col-span-4 col-span-12 p-2 ' +
                            (type === 'firms' || type === 'consultants'
                                ? 'lg:col-span-4'
                                : 'lg:col-span-6')
                        }
                        onClick={() => handleSort(type)}
                    >
                        <Arrow
                            className={`
                                            ${orderBy === 'title' ? 'opacity-100' : 'opacity-0'}
                                            ${order === 'asc' ? 'rotate-0' : 'rotate-180'}
                                            transition ease-in-out delay-150 group-hover:opacity-100
                                        `}
                            style={{
                                fontSize: '20px',
                                transition: 'all 0.15s ease',
                            }}
                        />
                        {replace_underscores_capitalize(type)}
                    </button>

                    <div
                        className={
                            'col-span-12 grid gap-2 ' +
                            (type === 'firms' || type === 'consultants'
                                ? 'lg:col-span-8 grid-cols-14'
                                : 'lg:col-span-6 grid-cols-12')
                        }
                    >
                        {type === 'inactive organizations' ? (
                            <div className="col-span-8"></div>
                        ) : (
                            <></>
                        )}
                        {(type === 'inactive organizations'
                            ? inactive_org_headers
                            : type === 'issues'
                            ? []
                            : headers
                        ).map(
                            (header, idx) =>
                                !(type === 'issues' && ['%', '+/-'].includes(header)) && (
                                    <button
                                        key={idx}
                                        className={`flex flex-row items-center lg:justify-end
                                            justify-between bg-white lg:bg-slate-50 rounded-xl 
                                            lg:shadow-none shadow-sm group hover:text-gray-900 
                                            ${
                                                type === 'issues'
                                                    ? 'lg:col-span-3 col-span-4'
                                                    : type === 'firms' || type === 'consultants'
                                                    ? 'lg:col-span-2 col-span-7'
                                                    : 'lg:col-span-2 col-span-4'
                                            } p-2`}
                                        onClick={() => handleSort(header)}
                                    >
                                        <Arrow
                                            className={`
                                                ${
                                                    orderBy === key_header_map.get(header)
                                                        ? 'opacity-100'
                                                        : 'opacity-0'
                                                }
                                                ${order === 'asc' ? 'rotate-0' : 'rotate-180'}
                                                transition ease-in-out delay-150 group-hover:opacity-100
                                            `}
                                            style={{
                                                fontSize: '20px',
                                                transition: 'all 0.15s ease',
                                            }}
                                        />
                                        {replace_underscores_capitalize(header)}
                                    </button>
                                )
                        )}
                    </div>
                </div>

                <hr className="col-start-2 col-end-12 h-0.5 my-3 bg-slate-100 rounded" />

                <div className="col-start-2 col-end-12">
                    {sorted_list.map((entity: entityListExtra, idx: number) => {
                        return (
                            <Grow key={idx} in={true}>
                                <div
                                    className={`${
                                        idx < selections.length && ' border-l-4 border-sky-400'
                                    } bg-white shadow-sm rounded-md px-1 my-3`}
                                >
                                    <div className="lg:text-slate-700 text-md grid grid-cols-12 gap-2">
                                        <div
                                            className={
                                                'flex flex-col items-start justify-center py-2 ' +
                                                (type === 'firms' || type === 'consultants'
                                                    ? 'lg:col-span-4 col-span-12'
                                                    : 'lg:col-span-6 col-span-12')
                                            }
                                        >
                                            <Link
                                                className="pl-2 text-blue-400 font-medium hover:underline"
                                                to={
                                                    type === 'organizations'
                                                        ? `/sectors/${parent_code}/organizations/${
                                                              entity.title
                                                          }?sector=${encodeURIComponent(
                                                              parent_name
                                                          )}`
                                                        : type === 'inactive organizations'
                                                        ? `/sectors/${parent_code}/organizations/inactive/${
                                                              entity.title
                                                          }?sector=${encodeURIComponent(
                                                              parent_name
                                                          )}`
                                                        : type === 'consultants'
                                                        ? `/firms/${encodeURIComponent(
                                                              parent_code
                                                          )}/consultants/${
                                                              entity.title
                                                          }?firm=${encodeURIComponent(parent_name)}`
                                                        : type === 'DPOHs'
                                                        ? `/institutions/${encodeURIComponent(
                                                              parent_code
                                                          )}/dpoh/${
                                                              entity.title
                                                          }?institution=${encodeURIComponent(
                                                              parent_name
                                                          )}${
                                                              entity.title2 &&
                                                              entity.title2 === 'Minister'
                                                                  ? '&includeHOC=true'
                                                                  : ''
                                                          }${
                                                              parent_code === '103'
                                                                  ? '&includeMinisterPosition=true&isMP=true'
                                                                  : ''
                                                          }`
                                                        : type === 'MPs'
                                                        ? `/institutions/103/dpoh/${entity.title}?institution=House of Commons&includeMinisterPosition=true&isMP=true`
                                                        : `/${type}/${encodeURIComponent(
                                                              entity.code
                                                          )}?name=${encodeURIComponent(
                                                              entity.title
                                                          )}`
                                                }
                                            >
                                                {entity.title}
                                                {entity.title2 && ' - ' + entity.title2}{' '}
                                                {type === 'committees' && '(' + entity.code + ')'}
                                            </Link>
                                            {type === 'sectors' ? (
                                                <div className="flex lg:flex-row flex-col items-start lg:gap-2">
                                                    <Button
                                                        component={Link}
                                                        to={`/sectors/${
                                                            entity.code
                                                        }/organizations?sector=${encodeURIComponent(
                                                            entity.title
                                                        )}`}
                                                        sx={{ px: 1, py: 0 }}
                                                        size="small"
                                                        color="success"
                                                    >
                                                        Organizations
                                                    </Button>
                                                    <Button
                                                        component={Link}
                                                        to={`/sectors/${
                                                            entity.code
                                                        }/organizations/inactive?sector=${encodeURIComponent(
                                                            entity.title
                                                        )}`}
                                                        sx={{ px: 1, py: 0 }}
                                                        size="small"
                                                        color="success"
                                                    >
                                                        Inactive Organizations
                                                    </Button>
                                                </div>
                                            ) : type === 'firms' ? (
                                                <Button
                                                    component={Link}
                                                    to={`/firms/${
                                                        entity.code
                                                    }/consultants?firm=${encodeURIComponent(
                                                        entity.title
                                                    )}`}
                                                    sx={{ px: 1, py: 0 }}
                                                    size="small"
                                                    color="info"
                                                >
                                                    Consultants
                                                </Button>
                                            ) : type === 'institutions' ? (
                                                <Button
                                                    component={Link}
                                                    to={`/institutions/${
                                                        entity.code
                                                    }/dpoh?institution=${encodeURIComponent(
                                                        entity.title
                                                    )}`}
                                                    sx={{ px: 1, py: 0 }}
                                                    size="small"
                                                    color="info"
                                                >
                                                    DPOH List
                                                </Button>
                                            ) : type === 'committees' ? (
                                                <Button
                                                    component={Link}
                                                    to={`/committees/${
                                                        entity.code
                                                    }/mps?committee=${encodeURIComponent(
                                                        entity.title
                                                    )}`}
                                                    sx={{ px: 1, py: 0 }}
                                                    size="small"
                                                    color="info"
                                                >
                                                    MP List
                                                </Button>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                        <div
                                            className={
                                                'grid grid-cols-12 ' +
                                                (type === 'firms' || type === 'consultants'
                                                    ? 'lg:col-span-8 col-span-12 lg:grid-cols-14'
                                                    : 'lg:col-span-6 col-span-12')
                                            }
                                        >
                                            {type === 'issues' ||
                                            type === 'inactive organizations' ? (
                                                <></>
                                            ) : (
                                                <div className={pill}>
                                                    <div className="lg:hidden flex">
                                                        {three_months_ago}:
                                                    </div>
                                                    {entity.two_months_ago || 0}
                                                </div>
                                            )}
                                            {type === 'issues' ||
                                            type === 'inactive organizations' ? (
                                                <></>
                                            ) : (
                                                <div className={pill}>
                                                    <div className="lg:hidden flex">
                                                        {two_months_ago}:
                                                    </div>
                                                    <div>{entity.last_month || 0}</div>
                                                </div>
                                            )}
                                            {type === 'issues' ||
                                            type === 'inactive organizations' ? (
                                                <></>
                                            ) : (
                                                <div
                                                    className={
                                                        (entity.change_abs > 0
                                                            ? 'text-liq-lime '
                                                            : 'text-liq-red ') + pill
                                                    }
                                                >
                                                    <div className="lg:hidden flex">+/-:</div>
                                                    <div>
                                                        {(+entity.change_abs > 0 ? '+' : '') +
                                                            entity.change_abs}
                                                    </div>
                                                </div>
                                            )}
                                            {type === 'issues' ||
                                            type === 'inactive organizations' ? (
                                                <></>
                                            ) : (
                                                <div
                                                    className={
                                                        (entity.change_rel > 0
                                                            ? 'text-liq-lime '
                                                            : 'text-liq-red ') + pill
                                                    }
                                                >
                                                    <div className="lg:hidden flex">%:</div>
                                                    <div>
                                                        {(+entity.change_rel > 0 ? '+' : '') +
                                                            entity.change_rel +
                                                            '%'}
                                                    </div>
                                                </div>
                                            )}
                                            {type === 'issues' ||
                                            type === 'inactive organizations' ? (
                                                <></>
                                            ) : (
                                                <div className={pill + ' text-slate-400'}>
                                                    <div className="lg:hidden flex">
                                                        {last_month}:
                                                    </div>
                                                    <div>{entity.mtd || 0}</div>
                                                </div>
                                            )}
                                            {type === 'inactive organizations' ? (
                                                <div className="col-span-8"></div>
                                            ) : (
                                                <></>
                                            )}
                                            {type !== 'issues' && (
                                                <div className={pill}>
                                                    <div className="lg:hidden flex">TTM:</div>
                                                    <div>{entity.ttm || 0}</div>
                                                </div>
                                            )}

                                            {type === 'inactive organizations' ? (
                                                <div className={pill}>
                                                    <div className="lg:hidden flex">36M:</div>
                                                    <div>{entity.t36m || 0}</div>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                            {type === 'firms' || type === 'consultants' ? (
                                                <div className={pill}>
                                                    <div className="lg:hidden flex">Reg:</div>
                                                    <div>
                                                        {entity.num_active_registrations || 0}
                                                    </div>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Grow>
                        );
                    })}
                    {sorted_list.length === 0 && <LoadingList />}
                </div>
            </div>
        </Fade>
    );
};

export default EntityListPage;
