import React, { useEffect, useState } from 'react';
import Dashboard from '../../../pages/dashboards/Dashboard';
import { useParams, useSearchParams } from 'react-router-dom';
import {
    entityCluster,
    entityLobbyingActivity,
    entityOverviews,
    entityRegistration,
} from '../../../interfaces/generic-entity.interface';

import { useAppDispatch } from '../../../store/store';
import {
    fetchSubjectOverview,
    fetchSubjectRecentLobbying,
    selectSubjectRecentLobbying,
    fetchOrganizationCluster,
    selectOrganizationCluster,
    selectInstitutionCluster,
    fetchInstitutionCluster,
    selectDPOHCluster,
    fetchDPOHCluster,
    fetchFirmCluster,
    selectFirmCluster,
    selectSubjectRecentLobbyingLoading,
    selectOrganizationClusterLoading,
    selectFirmClusterLoading,
    selectDPOHClusterLoading,
    selectInstitutionClusterLoading,
    selectSubjectOverview,
    selectSubjectOverviewLoading,
    selectSubjectRegistrations,
    selectSubjectRegistrationsLoading,
    fetchSubjectRegistrations,
    fetchSubjectDeregistrations,
    selectSubjectDeregistrations,
    selectSubjectDeregistrationsLoading,
    fetchSectorCluster,
    selectSectorCluster,
    selectSectorClusterLoading,
    selectSubjectExportLoading,
    selectSubjectExportFail,
    fetchSubjectUpdates,
    selectSubjectUpdates,
    selectSubjectUpdatesLoading,
} from '../../../features/subject-dashboards/store/subjectSlice';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { SubjectSectors } from '../../../tooltips/subjects/SubjectSectors';
import { SubjectOrganizations } from '../../../tooltips/subjects/SubjectOrganizations';
import { SubjectGovernmentInstitutions } from '../../../tooltips/subjects/SubjectGovernmentInstitutions';
import { SubjectPublicOffice } from '../../../tooltips/subjects/SubjectPublicOffice';
import { SubjectLobbyFirms } from '../../../tooltips/subjects/SubjectLobbyFirms';
import { selectUserPermissions } from '../../../store/reducers/userSlice';
import { SubscriptionRequired } from '../../../pages/misc/SubscriptionRequired';
import { recentUpdates } from '../../shared/interfaces/whats-new.interface';

const SubjectDataLayer = () => {
    const dispatch = useAppDispatch();

    // Get the id of the current entity
    const url_params = useParams();
    const [searchParams] = useSearchParams();

    const entityCode: string = url_params.entityId !== undefined ? url_params.entityId : '';

    const entityName: string = searchParams.get('name') || '';

    const overview: entityOverviews | null = useSelector(selectSubjectOverview);
    const overview_loading: boolean = useSelector(selectSubjectOverviewLoading);

    const updates: recentUpdates | null = useSelector(selectSubjectUpdates);
    const updates_loading: boolean = useSelector(selectSubjectUpdatesLoading);

    const activity: entityLobbyingActivity[] | null = useSelector(selectSubjectRecentLobbying);
    const activity_loading: boolean = useSelector(selectSubjectRecentLobbyingLoading);

    const registrations: entityRegistration[] | null = useSelector(selectSubjectRegistrations);
    const registrations_loading: boolean = useSelector(selectSubjectRegistrationsLoading);

    const deregistrations: entityRegistration[] | null = useSelector(selectSubjectDeregistrations);
    const deregistrations_loading: boolean = useSelector(selectSubjectDeregistrationsLoading);

    const sector_cluster: entityCluster | null = useSelector(selectSectorCluster);
    const sector_cluster_loading: boolean = useSelector(selectSectorClusterLoading);

    const org_cluster: entityCluster | null = useSelector(selectOrganizationCluster);
    const org_cluster_loading: boolean = useSelector(selectOrganizationClusterLoading);

    const institution_cluster: entityCluster | null = useSelector(selectInstitutionCluster);
    const institution_cluster_loading: boolean = useSelector(selectInstitutionClusterLoading);

    const firm_cluster: entityCluster | null = useSelector(selectFirmCluster);
    const firm_cluster_loading: boolean = useSelector(selectFirmClusterLoading);

    const dpoh_cluster: entityCluster | null = useSelector(selectDPOHCluster);
    const dpoh_cluster_loading: boolean = useSelector(selectDPOHClusterLoading);

    const export_loading: boolean = useSelector(selectSubjectExportLoading);
    const export_fail: boolean = useSelector(selectSubjectExportFail);

    const permissions = useSelector(selectUserPermissions);

    const [date, setDate] = useState<string>(moment.utc(moment()).format('YYYY-MM-DD'));

    useEffect(() => {
        if (permissions?.subjects) {
            dispatch(fetchSubjectUpdates({ code: entityCode, date: date }));
            dispatch(fetchSubjectOverview({ code: entityCode, date: date }));
            dispatch(
                fetchSubjectRecentLobbying({
                    code: entityCode,
                    date: date,
                })
            );
            dispatch(
                fetchSubjectRegistrations({
                    code: entityCode,
                    date: date,
                })
            );
            dispatch(
                fetchSubjectDeregistrations({
                    code: entityCode,
                    date: date,
                })
            );
            dispatch(fetchSectorCluster({ code: entityCode, date: date }));
            dispatch(fetchOrganizationCluster({ code: entityCode, date: date }));
            dispatch(fetchInstitutionCluster({ code: entityCode, date: date }));
            dispatch(fetchFirmCluster({ code: entityCode, date: date }));
            dispatch(fetchDPOHCluster({ code: entityCode, date: date }));
        }
    }, [dispatch, entityCode, entityName, date, permissions]);

    const overview_headers = ['count'];

    // Get the current year
    const currentYear = moment(date, 'YYYY-MM-DD').year();

    // Populate the list with the last 8 years
    for (let i = currentYear; i >= currentYear - 6; i--) {
        overview_headers.push(String(i));
    }

    return permissions?.subjects ? (
        <Dashboard
            type="subjects"
            entity_code={entityCode}
            date={date}
            handleDateChange={setDate}
            includeUpdates
            updates={updates}
            updatesLoading={updates_loading}
            includeOverview
            overview={overview}
            overviewHeaders={overview_headers}
            overviewLoading={overview_loading}
            overviewIndent={['by Consultants', 'of Ministers or DMs']}
            includeRecentLobbying
            recentLobbying={activity}
            recentLobbyingLoading={activity_loading}
            includeRegistrations
            recentRegistrations={registrations}
            recentRegistrationsLoading={registrations_loading}
            includeDeregistrations
            recentDeregistrations={deregistrations}
            recentDeregistrationsLoading={deregistrations_loading}
            exportFail={export_fail}
            exportLoading={export_loading}
            clusters={[
                {
                    cluster_data: sector_cluster,
                    title: 'Sectors',
                    tooltipContent: SubjectSectors,
                    loading: sector_cluster_loading,
                },
                {
                    cluster_data: org_cluster,
                    title: 'Organizations',
                    tooltipContent: SubjectOrganizations,
                    loading: org_cluster_loading,
                },
                {
                    cluster_data: institution_cluster,
                    title: 'Government Institutions',
                    tooltipContent: SubjectGovernmentInstitutions,
                    loading: institution_cluster_loading,
                },
                {
                    cluster_data: dpoh_cluster,
                    title: 'Designated Public Office Holders',
                    tooltipContent: SubjectPublicOffice,
                    loading: dpoh_cluster_loading,
                },
                {
                    cluster_data: firm_cluster,
                    title: 'Lobby Firms',
                    tooltipContent: SubjectLobbyFirms,
                    loading: firm_cluster_loading,
                },
            ]}
        />
    ) : (
        <SubscriptionRequired
            pageName="Subject dashboards are"
            minimumTier="Corporate or Enterprise"
        />
    );
};

export default SubjectDataLayer;
