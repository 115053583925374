import { Link } from 'react-router-dom';
import { Divider, MenuItem, Menu } from '@mui/material';

import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { MP_FN } from '../../features/dpoh-dashboards/constants';

const link_tran = 'text-slate-600 hover:text-black';

interface Props {
    open: boolean;
    handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    handleClose: () => void;
    anchorEl: HTMLElement | null;
}

const DashboardsButton = ({ open, handleClick, handleClose, anchorEl }: Props) => {
    return (
        <div
            className={`${
                open
                    ? 'bg-none outline outline-1 rounded-md text-liq-gray-blue'
                    : 'bg-liq-blue rounded-md text-white'
            }`}
        >
            <button
                className="py-1 px-3 flex flex-row justify-center"
                id="dashboards-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                Dashboards
                {open ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
            </button>
            <Menu
                sx={{
                    marginTop: '14px',
                    '& .MuiMenu-paper': {
                        backgroundColor: 'white',
                        borderTopWidth: 6,
                        borderTopColor: '#67A8CC',
                        borderRadius: '0px 0px 10px 10px',
                        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 4px -2px',
                    },
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                disableScrollLock={true}
            >
                <div className={link_tran}>
                    <MenuItem onClick={handleClose} component={Link} to="/sectors">
                        Sectors
                    </MenuItem>
                </div>
                <div className={link_tran}>
                    <MenuItem onClick={handleClose} component={Link} to="/institutions">
                        Institutions
                    </MenuItem>
                </div>
                <div className={link_tran}>
                    <MenuItem
                        onClick={handleClose}
                        component={Link}
                        to={`/institutions/103/dpoh?institution=${encodeURIComponent(
                            'House of Commons'
                        )}&title=${encodeURIComponent(MP_FN)}`}
                    >
                        Members of Parliament
                    </MenuItem>
                </div>
                <div className={link_tran}>
                    <MenuItem onClick={handleClose} component={Link} to="/committees">
                        Parliamentary Committees
                    </MenuItem>
                </div>
                <div className={link_tran}>
                    <MenuItem onClick={handleClose} component={Link} to="/subjects">
                        Subjects
                    </MenuItem>
                </div>
                <div className={link_tran}>
                    <MenuItem onClick={handleClose} component={Link} to="/issues">
                        Issues
                    </MenuItem>
                </div>
                <div className={link_tran}>
                    <MenuItem onClick={handleClose} component={Link} to="/firms">
                        Lobby Firms
                    </MenuItem>
                </div>
                <Divider sx={{ marginY: 1 }} />
                <div className={link_tran}>
                    <MenuItem onClick={handleClose} component={Link} to="/in-house">
                        In-House
                    </MenuItem>
                </div>
                <Divider sx={{ marginY: 1 }} />
                <div className={link_tran}>
                    <MenuItem onClick={handleClose} component={Link} to="/big-picture">
                        Big Picture
                    </MenuItem>
                </div>
            </Menu>
        </div>
    );
};

export default DashboardsButton;
