import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../store/store';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    TextField,
    Autocomplete,
    Fade,
    Select,
    MenuItem,
    Divider,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import LoadSpinner from '../../components/misc/LoadSpinner';
import {
    selectUserLoading,
    selectEmail,
    selectActionLoading,
    selectUserErrored,
    selectCompany,
    selectFirstName,
    selectLastName,
    applyForEmailTrial,
    EmailTrialRequest,
} from '../../store/reducers/userSlice';
import LoadPage from '../misc/LoadPage';
import SendIcon from '@mui/icons-material/Send';
import validator from 'validator';
import { entityListExtra } from '../../interfaces/generic-entity.interface';
import {
    fetchCommitteeList,
    selectCommitteeList,
} from '../../features/committee-dashboards/data/committeeListSlice';
import {
    fetchSectorList,
    selectSectorList,
} from '../../features/sector-dashboards/store/sectorListSlice';
// import ClearIcon from '@mui/icons-material/Clear';
import HelpIcon from '@mui/icons-material/Help';
import Modal from '../../components/misc/Modal';

const EmailTrialPage = () => {
    const dispatch = useAppDispatch();

    const userLoading = useSelector(selectUserLoading);

    const trialLoading = useSelector(selectActionLoading);
    const trialError = useSelector(selectUserErrored);

    const existingEmail = useSelector(selectEmail);
    const existingFirstName = useSelector(selectFirstName);
    const existingLastName = useSelector(selectLastName);
    const existingCompany = useSelector(selectCompany);

    const [email, setEmail] = useState(existingEmail || '');
    const [firstName, setFirstName] = useState(existingFirstName || '');
    const [lastName, setLastName] = useState(existingLastName || '');
    const [company, setCompany] = useState(existingCompany || '');

    const [committeeMeetings, setCommitteeMeetings] = useState<any[]>([null, null, null]);
    const [stakeholderSectors, setStakeholderSectors] = useState<any[]>([null, null, null]);
    // const [keywordAlerts, setKeywordAlerts] = useState<string[]>(['', '', '']);
    const [grInsider, setGrInsider] = useState(1);
    const [questionPeriod, setQuestionPeriod] = useState(1);
    // const [customIssue, setCustomIssue] = useState(1);

    const [emailError, setEmailError] = useState(false);
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [companyError, setCompanyError] = useState(false);

    const [committeeModalOpen, setCommitteeModalOpen] = useState(false);
    const [sectorModalOpen, setSectorModalOpen] = useState(false);
    const [issueModalOpen, setIssueModalOpen] = useState(false);
    const [keywordModalOpen, setKeywordModalOpen] = useState(false);
    const [grModalOpen, setGRModalOpen] = useState(false);
    const [qpModalOpen, setQPModalOpen] = useState(false);

    const [resultModalOpen, setResultModalOpen] = React.useState(false);

    const committeeList: entityListExtra[] | null = useSelector(selectCommitteeList);
    const sectorList: entityListExtra[] | null = useSelector(selectSectorList);

    useEffect(() => {
        if (!committeeList) dispatch(fetchCommitteeList());
        if (!sectorList) dispatch(fetchSectorList());
    }, [dispatch, committeeList, sectorList]);

    const renderOptions = (props: React.HTMLAttributes<HTMLLIElement>, option: entityListExtra) => {
        return (
            <Fade in={true} key={option.code || option.title}>
                <li {...props} style={{ fontSize: 14 }}>
                    {option.title}
                </li>
            </Fade>
        );
    };

    // const modifyKeywords = (keyword: string, index: number) => {
    //     const newKeywords = keywordAlerts.map((existingKeyword, i) => {
    //         if (i === index) {
    //             // replace the intended keyword
    //             return keyword;
    //         } else {
    //             // The rest haven't changed
    //             return existingKeyword;
    //         }
    //     });
    //     setKeywordAlerts(newKeywords);
    // };

    // const removeKeyword = (index: number) => {
    //     const newKeywords = keywordAlerts.map((keyword, i) => (i === index ? '' : keyword));
    //     setKeywordAlerts(newKeywords);
    // };

    const handleClickOpen = () => {
        setResultModalOpen(true);
    };

    const handleClose = () => {
        setResultModalOpen(false);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setEmailError(false);

        // check for required fields
        if (!validator.isEmail(email)) {
            setEmailError(true);
        }
        if (firstName === '') {
            setFirstNameError(true);
        }
        if (lastName === '') {
            setLastNameError(true);
        }
        if (company === '') {
            setCompanyError(true);
        }

        if (validator.isEmail(email) && firstName !== '' && lastName !== '' && company !== '') {
            const trialRequest: EmailTrialRequest = {
                email,
                name: `${firstName} ${lastName}`,
                company,
                committeeMeetings: committeeMeetings.filter(meeting => meeting !== null),
                stakeholderSectors: stakeholderSectors.filter(sector => sector !== null),
                keywordAlerts: [], // keywordAlerts.filter(keyword => keyword !== ''),
                grInsider: grInsider ? true : false,
                questionPeriod: questionPeriod ? true : false,
                customIssue: false //customIssue ? true : false,
            };
            dispatch(applyForEmailTrial(trialRequest));

            handleClickOpen();
        } else {
            window.scrollTo(0, 0);
        }
    };

    return userLoading ? (
        <LoadPage />
    ) : (
        <div className="lg:p-16 lg:pt-32 pt-24 p-10 mb-16">
            <Dialog
                open={resultModalOpen}
                onClose={trialLoading ? () => {} : handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {trialLoading
                        ? 'Processing Trial Request'
                        : trialError
                        ? 'Error'
                        : 'Request Sent'}
                </DialogTitle>
                <DialogContent>
                    {trialLoading ? (
                        <div className="flex flex row justify-center items-center">
                            <LoadSpinner />
                        </div>
                    ) : (
                        <DialogContentText id="alert-dialog-description">
                            {trialError
                                ? 'Error submitting trial application. Please refresh the page and try again.'
                                : 'Thank you for applying for a LobbyIQ email trial. Once we process your application, expect to receive an email marking the beginning of your trial.'}
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus disabled={trialLoading}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Modal
                title={'Committee Summaries'}
                open={committeeModalOpen}
                width={30}
                onClose={() => setCommitteeModalOpen(false)}
            >
                {
                    'Three-page summary and transcript of Committee meeting delivered within an hour of meeting conclusion.'
                }
            </Modal>
            <Modal
                title={'Stakeholder Lobbying Update'}
                open={sectorModalOpen}
                width={30}
                onClose={() => setSectorModalOpen(false)}
            >
                {
                    'Weekly report on recently posted stakeholder lobbying communications and registrations for selected sectors. LobbyIQ subscribers receive weekly lobbying reports additionally for selected organizations, institutions and DPOHs.'
                }
            </Modal>
            <Modal
                title={'Custom Issue Alerts'}
                open={issueModalOpen}
                width={30}
                onClose={() => setIssueModalOpen(false)}
            >
                {
                    'Weekly monitoring report on lobbying activity by Issue. Subscribers create up to 10 custom issues with specific keywords to monitor.'
                }
            </Modal>
            <Modal
                title={'Keyword Alerts'}
                open={keywordModalOpen}
                width={30}
                onClose={() => setKeywordModalOpen(false)}
            >
                {'Daily email with list of keyword mentions during previous day in Hansard, Committee meetings and Question Period. LobbyIQ subscribers also have the option ' +
                    'of receiving real-time alerts of keyword mentions during Committee meetings and Question Period, delivered within an hour of the meeting or QP finishing.'}
            </Modal>
            <Modal
                title={'GR Insider'}
                open={grModalOpen}
                width={30}
                onClose={() => setGRModalOpen(false)}
            >
                {
                    'Weekly email update on new federal lobby registrations and de-registrations by organizations, lobby firms and consultants, and changes in in-house GR teams.'
                }
            </Modal>
            <Modal
                title={'Question Period'}
                open={qpModalOpen}
                width={30}
                onClose={() => setQPModalOpen(false)}
            >
                {
                    'Daily three-page summary report and transcript of Question Period, delivered within an hour of the Question Period conclusion.'
                }
            </Modal>
            <div className="flex flex-col justify-center items-center text-center mb-12">
                <div className="text-2xl">
                    Please complete this form to receive{' '}
                    <b>free Parliamentary and Stakeholder Monitoring</b> email updates for a{' '}
                    <b>two-week period</b>.
                </div>
            </div>
            <form autoComplete="off" onSubmit={handleSubmit}>
                <div className="flex lg:flex-row md:flex-row flex-col justify-center items-center lg:pb-8 pb-6">
                    <div className="mb-5 lg:mb-0 md:mb-0">
                        <div className="p-3">Business Email</div>
                        <div className="lg:w-96 w-72 lg:pr-4 md:pr-4">
                            <TextField
                                fullWidth
                                id="email"
                                placeholder="Business Email"
                                variant="outlined"
                                required
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                error={emailError}
                                {...(emailError ? { label: 'Invalid email' } : {})}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="lg:pl-6 md:pl-6 p-3">Company/Organization</div>
                        <div className="lg:w-96 w-72 lg:pl-4 md:pl-4">
                            <TextField
                                fullWidth
                                id="comp-org"
                                placeholder="Company/Organization"
                                variant="outlined"
                                required
                                onChange={(e) => setCompany(e.target.value)}
                                value={company}
                                error={companyError}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex lg:flex-row md:flex-row flex-col justify-center items-center lg:pb-12 pb-8">
                    <div className="mb-5 lg:mb-0 md:mb-0">
                        <div className="p-3">First Name</div>
                        <div className="lg:w-96 w-72 lg:pr-4 md:pr-4">
                            <TextField
                                fullWidth
                                id="first-name"
                                placeholder="First Name"
                                variant="outlined"
                                required
                                onChange={(e) => setFirstName(e.target.value)}
                                value={firstName}
                                error={firstNameError}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="lg:pl-6 md:pl-6 p-3">Last Name</div>
                        <div className="lg:w-96 w-72 lg:pl-4 md:pl-4">
                            <TextField
                                fullWidth
                                id="last-name"
                                placeholder="Last Name"
                                variant="outlined"
                                required
                                onChange={(e) => setLastName(e.target.value)}
                                value={lastName}
                                error={lastNameError}
                            />
                        </div>
                    </div>
                </div>
                <Divider sx={{ marginBottom: 3 }} />
                <div className="grid grid-cols-12 justify-center items-center md:h-[100px]">
                    <div className="col-span-1" />
                    <div className="flex flex-row justify-start p-2 md:col-span-2 col-span-12 text-xl font-bold">
                        <div
                            className="ml-2 mr-6 hover:cursor-pointer"
                            onClick={() => setCommitteeModalOpen(true)}
                        >
                            <HelpIcon color="info" />
                        </div>
                        Committee Meetings
                    </div>

                    <div className="flex flex-col md:flex-row justify-between col-span-8 mb-5 md:mb-0">
                        {[...Array(3)].map((_, idx) => (
                            <div className="px-5 w-96 mb-5 md:mb-0">
                                <Autocomplete
                                    isOptionEqualToValue={(
                                        option: entityListExtra,
                                        value: entityListExtra
                                    ) => option.title === value.title}
                                    fullWidth
                                    disableListWrap
                                    size="small"
                                    value={committeeMeetings[idx] || ''}
                                    onChange={(e, newValue) => {
                                        const newCommittees = committeeMeetings.map(
                                            (existingValue, i) => {
                                                if (i === idx) {
                                                    // replace the intended value
                                                    return newValue;
                                                } else {
                                                    // The rest haven't changed
                                                    return existingValue;
                                                }
                                            }
                                        );
                                        setCommitteeMeetings(newCommittees);
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Committees" />
                                    )}
                                    options={committeeList || []}
                                    getOptionLabel={(option: any) => option.title ?? ''}
                                    renderOption={renderOptions}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="grid grid-cols-12 justify-center items-center md:h-[100px]">
                    <div className="col-span-1" />
                    <div className="flex flex-row justify-start p-2 md:col-span-2 col-span-12 text-xl font-bold">
                        <div
                            className="ml-2 mr-6 hover:cursor-pointer"
                            onClick={() => setSectorModalOpen(true)}
                        >
                            <HelpIcon color="info" />
                        </div>
                        Stakeholder Lobbying Update
                    </div>

                    <div className="flex flex-col md:flex-row justify-between col-span-8 mb-5 md:mb-0">
                        {[...Array(3)].map((_, idx) => (
                            <div className="px-5 w-96 mb-5 md:mb-0">
                                <Autocomplete
                                    isOptionEqualToValue={(
                                        option: entityListExtra,
                                        value: entityListExtra
                                    ) => option.title === value.title}
                                    fullWidth
                                    disableListWrap
                                    size="small"
                                    value={stakeholderSectors[idx] || ''}
                                    onChange={(e, newValue) => {
                                        const newList = stakeholderSectors.map(
                                            (existingValue, i) => {
                                                if (i === idx) {
                                                    // replace the intended value
                                                    return newValue;
                                                } else {
                                                    // The rest haven't changed
                                                    return existingValue;
                                                }
                                            }
                                        );
                                        setStakeholderSectors(newList);
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Sectors" />
                                    )}
                                    options={sectorList || []}
                                    getOptionLabel={(option: any) => option.title ?? ''}
                                    renderOption={renderOptions}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                {/* <div className="grid grid-cols-12 justify-center items-center md:h-[100px]">
                    <div className="col-span-1" />
                    <div className="flex flex-row justify-start p-2 md:col-span-2 col-span-12 text-xl font-bold">
                        <div
                            className="ml-2 mr-6 hover:cursor-pointer"
                            onClick={() => setKeywordModalOpen(true)}
                        >
                            <HelpIcon color="info" />
                        </div>
                        Keyword Alerts
                    </div>

                    <div className="flex flex-col md:flex-row justify-between col-span-8 mb-5 md:mb-0">
                        {keywordAlerts.map((keyword, idx) => (
                            <div className="w-96 px-4 mb-5 md:mb-0">
                                <TextField
                                    fullWidth
                                    id={'keyword' + idx}
                                    placeholder="Enter Keyword"
                                    variant="outlined"
                                    size="small"
                                    autoComplete="off"
                                    onChange={(e) => modifyKeywords(e.target.value, idx)}
                                    value={keyword}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton onClick={() => removeKeyword(idx)}>
                                                    <ClearIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                </div> */}
                <div className="grid grid-cols-12 justify-center items-center md:h-[100px]">
                    <div className="col-span-1" />
                    <div className="flex flex-row justify-start pl-4 col-span-12 md:col-span-2 text-xl font-bold">
                        <div
                            className="mr-5 hover:cursor-pointer"
                            onClick={(e) => {
                                e.preventDefault();
                                setGRModalOpen(true);
                            }}
                        >
                            <HelpIcon color="info" />
                        </div>
                        GR Insider
                    </div>
                    <div className="flex flex-row justify-between col-span-12 md:col-span-3 px-4 mb-5 mt-2 lg:mb-0 md:my-0">
                        <div className="w-96 pr-8 mb-5 md:mb-0">
                            <Select
                                value={grInsider}
                                fullWidth
                                size="small"
                                MenuProps={{ disableScrollLock: true }}
                                onChange={(e) =>
                                    setGrInsider(e.target.value as number)
                                }
                            >
                                <MenuItem value={1}>Include</MenuItem>
                                <MenuItem value={0}>Exclude</MenuItem>
                            </Select>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-12 justify-center items-center md:h-[100px]">
                    <div className="col-span-1" />
                    <div className="flex flex-row justify-start pl-4 col-span-12 md:col-span-2 text-xl font-bold">
                        <div
                            className="mr-5 hover:cursor-pointer"
                            onClick={(e) => {
                                e.preventDefault();
                                setQPModalOpen(true);
                            }}
                        >
                            <HelpIcon color="info" />
                        </div>
                        Question Period
                    </div>
                    <div className="flex flex-row justify-between col-span-12 md:col-span-3 px-4 mb-5 mt-2 lg:mb-0 md:my-0">
                        <div className="w-96 pr-8 mb-5 md:mb-0">
                            <Select
                                value={questionPeriod}
                                fullWidth
                                size="small"
                                MenuProps={{ disableScrollLock: true }}
                                onChange={(e) => setQuestionPeriod(e.target.value as number)}
                            >
                                <MenuItem value={1}>Include</MenuItem>
                                <MenuItem value={0}>Exclude</MenuItem>
                            </Select>
                        </div>
                    </div>
                </div>
                {/* <div className="grid grid-cols-12 justify-center items-center md:h-[100px]">
                    <div className="col-span-1" />
                    <div className="flex flex-row justify-start pl-4 col-span-12 md:col-span-2 text-xl font-bold">
                        <div
                            className="mr-5 hover:cursor-pointer"
                            onClick={(e) => {
                                e.preventDefault();
                                setIssueModalOpen(true);
                            }}
                        >
                            <HelpIcon color="info" />
                        </div>
                        Custom Issue Alerts
                    </div>
                    <div className="flex flex-row justify-between col-span-12 md:col-span-3 px-4 mb-5 mt-2 lg:mb-0 md:my-0">
                        <div className="w-96 pr-8 mb-5 md:mb-0">
                            <Select
                                value={customIssue}
                                fullWidth
                                size="small"
                                MenuProps={{ disableScrollLock: true }}
                                onChange={(e) => setCustomIssue(e.target.value as number)}
                            >
                                <MenuItem value={1}>Include</MenuItem>
                                <MenuItem value={0}>Exclude</MenuItem>
                            </Select>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row justify-between col-span-12 md:col-span-5 mb-5 md:mb-0">
                        If you wish to trial a custom issue alert, our team will reach out to you to
                        create a custom issue.
                    </div>
                </div> */}
                <div className="flex flex-row justify-center items-center mt-4">
                    <Button
                        className="bg-slate-600 w-72"
                        variant="contained"
                        size="large"
                        type="submit"
                        endIcon={<SendIcon />}
                    >
                        <div className="font-sans not-italic font-bold text-normal">Request</div>
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default EmailTrialPage;
