import axios from 'axios';
import {
    entityCluster,
    keywordMonthRaw,
    entityLobbyingActivity,
    entityRegistration,
    entityOverviews,
} from '../../../interfaces/generic-entity.interface';
import {
    issueInfo,
    keywordMentions,
    topic,
    topicOverview,
} from '../interface/issue-entity.interface';
import {
    DPOHLinkFormatter,
    consultantLinkFormatter,
    institutionLinkFormatter,
    lobbyFirmLinkFormatter,
    organizationLinkFormatter,
    sectorLinkFormatter,
} from '../../../helpers/internal_routing';
import { bumperSuperList } from '../../../interfaces/bumper-types.interface';
import { committeeMeetingLinkFormatter } from '../../../helpers/external_routing';
import { recentUpdates } from '../../shared/interfaces/whats-new.interface';

// Base URL for accessing the committee API
const baseURL = process.env.REACT_APP_API_BASE_URL;

// Fetch an overview of a given issue
const fetchUpdates = async (code: string | undefined, date: string) => {
    const response = await axios.get(`${baseURL}/issue/${code}/updates?date=${date}`);
    const data: recentUpdates = response.data;
    return data;
};

// Fetch an overview of a given issue
const fetchKeywordMentions = async (code: string | undefined, date: string) => {
    const mentionsResponse = await axios.get(
        `${baseURL}/issue/${code}/keywordsSentenceCounts?date=${date}`
    );
    const mentionsData: keywordMentions[] = mentionsResponse.data.issueKeywords;
    return mentionsData;
};

// Fetch info for a given issue
const fetchInfo = async (code: string | undefined) => {
    const issueInfoResponse = await axios.get(`${baseURL}/issue/${code}/info`);
    const issueInfoData: issueInfo = issueInfoResponse.data;
    return issueInfoData;
};

// Fetch an overview of a given issue
const fetchOverview = async (code: string | undefined, date: string) => {
    const overviewResponse = await axios.get(`${baseURL}/issue/${code}/overviews?date=${date}`);
    const overviewData: entityOverviews = overviewResponse.data;
    return overviewData;
};

// Fetch an overview of topics for a given issue
const fetchTopicOverview = async (code: string | undefined, date: string) => {
    const topic_response = await axios.get(`${baseURL}/issue/${code}/topicsoverview?date=${date}`);

    const topic_data: topic[] = topic_response.data.issueTopicsOverview;

    const topic_overview: topicOverview = {
        core_topics: topic_data.filter((topic: topic) => topic.mapped === 1),
        related_topics: topic_data.filter((topic: topic) => topic.mapped === 0),
    };

    return topic_overview;
};

// Fetch meeting sentences from recent meetings
const fetchSentences = async (
    code: string | undefined,
    date: string | undefined
): Promise<bumperSuperList[]> => {
    const sentence_response = await axios.get(
        `${baseURL}/issue/${code}/sentences?limit=6&date=${date}`
    );
    const sentence_data: any[] = sentence_response.data;

    const sentence_list: any[] = sentence_data.map((entry: keywordMonthRaw) => {
        return {
            ...entry,
            title:
                entry.meetingNo &&
                entry.parliament &&
                entry.session &&
                entry.committee &&
                entry.committee !== 'HANS'
                    ? committeeMeetingLinkFormatter(
                          entry.committee,
                          entry.parliament,
                          entry.session,
                          entry.meetingNo,
                          entry.title
                              ? `${entry.committee} (Meeting ${entry.meetingNo} - ${entry.date}): ${entry.title} `
                              : `${entry.committee}: ${entry.date} - Meeting ${entry.meetingNo}`
                      )
                    : entry.title
                    ? `${entry.committee}: ${entry.title} (Meeting ${entry.meetingNo} - ${entry.date})`
                    : `${entry.committee}: ${entry.date} - Meeting ${entry.meetingNo}`,
            lists: entry.datasets,
        };
    });

    return sentence_list;
};

// Fetch recent lobbying communications in a given issue
const fetchRecentLobbyingActivity = async (code: string, date: string | undefined) => {
    const response = await axios.get(`${baseURL}/issue/${code}/activity?date=${date}`);
    const data: entityLobbyingActivity[] = response.data;
    const converted_data: entityLobbyingActivity[] = data.map((entry) => {
        return {
            ...entry,
            organization:
                entry.sector_id && entry.sector
                    ? organizationLinkFormatter(entry.sector_id, entry.sector, entry.client)
                    : entry.client,
            sector:
                entry.sector_id && entry.sector
                    ? sectorLinkFormatter(entry.sector_id, entry.sector)
                    : entry.sector,
            dpoh:
                entry.institution_id && entry.institution
                    ? DPOHLinkFormatter(entry.institution_id, entry.institution, entry.dpoh)
                    : entry.dpoh,
            institution:
                entry.institution_id && entry.institution
                    ? institutionLinkFormatter(entry.institution_id, entry.institution)
                    : entry.institution,
            lobby_firm:
                entry.lobby_firm !== 'In-House'
                    ? lobbyFirmLinkFormatter(entry.lobby_firm)
                    : 'In-House',
            consultant:
                entry.consultant === 'In-House'
                    ? 'In-House'
                    : entry.lobby_firm === 'In-House'
                    ? entry.consultant
                    : consultantLinkFormatter(entry.lobby_firm, entry.consultant),
            link: `https://lobbycanada.gc.ca/app/secure/ocl/lrs/do/cmmLgPblcVw?comlogId=${entry.comm}`,
        };
    });
    return converted_data;
};

// Fetch recent registrations in a given issue
const fetchRecentRegistrations = async (
    code: string,
    date: string | undefined
): Promise<entityRegistration[]> => {
    const response = await axios.get(`${baseURL}/issue/${code}/recentregistrations?date=${date}`);
    const data: entityRegistration[] = response.data;
    const converted_data: entityRegistration[] = data.map((entry) => {
        return {
            ...entry,
            organization:
                entry.sector_id && entry.sector
                    ? organizationLinkFormatter(entry.sector_id, entry.sector, entry.client)
                    : entry.client,
            sector:
                entry.sector_id && entry.sector
                    ? sectorLinkFormatter(entry.sector_id, entry.sector)
                    : entry.sector,
            lobby_firm:
                entry.lobby_firm === 'In-House'
                    ? 'In-House'
                    : lobbyFirmLinkFormatter(entry.lobby_firm),
            consultant:
                entry.consultant === 'In-House'
                    ? 'In-House'
                    : entry.lobby_firm === 'In-House'
                    ? entry.consultant
                    : consultantLinkFormatter(entry.lobby_firm, entry.consultant),
            link: `https://lobbycanada.gc.ca/app/secure/ocl/lrs/do/vwRg?cno=${entry.corp}&regId=${entry.registration}#regStart`,
            dashboard: `link:/registrations/${entry.registration}`,
        };
    });
    return converted_data;
};

// Fetch recent deregistrations in a given issue
const fetchRecentDeregistrations = async (
    code: string,
    date: string | undefined
): Promise<entityRegistration[]> => {
    const response = await axios.get(`${baseURL}/issue/${code}/recentderegistrations?date=${date}`);
    const data: entityRegistration[] = response.data;
    const converted_data: entityRegistration[] = data.map((entry) => {
        return {
            ...entry,
            organization:
                entry.sector_id && entry.sector
                    ? organizationLinkFormatter(entry.sector_id, entry.sector, entry.client)
                    : entry.client,
            sector:
                entry.sector_id && entry.sector
                    ? sectorLinkFormatter(entry.sector_id, entry.sector)
                    : entry.sector,
            lobby_firm:
                entry.lobby_firm === 'In-House'
                    ? 'In-House'
                    : lobbyFirmLinkFormatter(entry.lobby_firm),
            consultant:
                entry.consultant === 'In-House'
                    ? 'In-House'
                    : entry.lobby_firm === 'In-House'
                    ? entry.consultant
                    : consultantLinkFormatter(entry.lobby_firm, entry.consultant),
            link: `https://lobbycanada.gc.ca/app/secure/ocl/lrs/do/vwRg?regId=${entry.registration}&cno=${entry.corp}#regStart`,
            dashboard: `link:/registrations/${entry.registration}`,
        };
    });
    return converted_data;
};

// Fetch an issue data cluster of the given issue code, under a specific "type"
const fetchCluster = async (
    code: string | undefined,
    type:
        | 'organizations'
        | 'institutions'
        | 'lobbyfirms'
        | 'dpoh'
        | 'subject'
        | 'sectors'
        | 'mentions',
    date: string | undefined
): Promise<entityCluster> => {
    // Fetch top 6 *type* within the given sector (determined by YTD communication count)
    const cluster_response = await axios.get(
        `${baseURL}/issue/${code}/${type === 'mentions' ? '' : 'cluster/'}${type}?date=${date}`
    );
    const cluster_data: entityCluster = cluster_response.data;
    return cluster_data;
};

const createOrUpdateIssue = async (
    issueName: string,
    issueKeywords: string[],
    issueId: number | null
) => {
    await axios.post(`${baseURL}/issue/create`, {
        issueName,
        issueKeywords,
        issueId,
    });
};

const deleteIssue = async (issueId: number) => {
    await axios.delete(`${baseURL}/issue/delete/${issueId}`);
};

export const issueAPI = {
    fetchUpdates,
    fetchKeywordMentions,
    fetchInfo,
    fetchOverview,
    fetchTopicOverview,
    fetchSentences,
    fetchRecentLobbyingActivity,
    fetchRecentRegistrations,
    fetchRecentDeregistrations,
    fetchCluster,
    createOrUpdateIssue,
    deleteIssue,
};
