import React, { useState } from 'react';
import LoadingIssueRankings from '../generic-dashboards/loading/LoadingIssueRankings';
import { MissingData } from '../misc/MissingData';
import HelpIcon from '@mui/icons-material/Help';
import Modal from '../misc/Modal';
import { bumperSuperList } from '../../interfaces/bumper-types.interface';
import Bumper from './bumpers/Bumper';

// Title and subtitle are optional components with defaults specified in function signature below.
interface keywordRankingsProps {
    title?: string;
    subtitle?: string;
    keywordData: bumperSuperList[] | null;
    hiddenKeys?: string[];
    expandPrompt?: string;
    tooltipContent?: JSX.Element;
    loading: boolean;
}

const KeywordRankings = ({
    title = '',
    subtitle = '',
    keywordData,
    hiddenKeys,
    expandPrompt,
    tooltipContent,
    loading,
}: keywordRankingsProps) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    return (
        <div>
            <div className="flex lg:flex-row flex-col justify-between">
                <h2 className="text-xl">{title}</h2>
                <div className="flex flex-row items-center">
                    <div className="lg:text-xl text-lg font-light">{subtitle}</div>
                    {tooltipContent && (
                        <div
                            className="ml-2 hover:cursor-pointer"
                            onClick={() => setModalOpen(true)}
                        >
                            <HelpIcon color="info" />
                        </div>
                    )}
                </div>
            </div>
            <Modal title={title} open={modalOpen} width={50} onClose={() => setModalOpen(false)}>
                {tooltipContent}
            </Modal>
            <hr className="h-0.5 my-3 bg-slate-100 rounded" />
            {loading ? (
                <LoadingIssueRankings />
            ) : !loading && !keywordData ? (
                <MissingData />
            ) : keywordData ? (
                <div className="col-span-2">
                    <Bumper
                        lists={keywordData}
                        hiddenKeys={hiddenKeys}
                        expandPrompt={expandPrompt}
                    />
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default KeywordRankings;
