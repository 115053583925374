import { Link } from 'react-router-dom';
import MetaTag from '../../components/metatag-component/MetaTag';

const IssueTrackFeatures = () => {
    return (
        <div className="xl:mx-24 mx-8 ">
            <MetaTag
                title="Issue Tracker"
                keywords="Monitor Lobbying by Subject,Custom Issue Dashboards,Keyword Alerts,Weekly Issue Emails,Monitor Lobbying, Subject Dashboards, Real-Time Metrics, Text Analytics, Federal Lobby Registry, Policy Issues, Custom Issue Dashboards, Keyword Alerts, Reputation Risk, Crisis Management, Weekly Issue Emails, Lobby Communications, Parliamentary Discussions, Registration Texts"
                description="Track and monitor federal lobbying by subject with real-time metrics, custom issue dashboards, and keyword alerts. Receive weekly summaries and alerts on critical policy issues and lobbying communications for comprehensive reputation risk and crisis management."
            />
            <div className="col-12 md:col-7 lg:col-6 py-14 lg:gap-12 gap-y-8 lg:gap-y-0"></div>

            <div className="lg:grid grid-cols-12 grid-rows-auto gap-8 py-8 flex flex-col ">
                <div className="col-start-1 row-start-1 col-span-12 col-end-12 text-left">
                    <h1 className="lg:text-4xl text-3xl ">Issue Tracking</h1>
                </div>

                <div className="lg:col-start-1 row-start-2 col-start-2 col-end-7 grid grid-cols-12 gap-4">
                    <div className="lg:col-span-6 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between min-h-[20rem]">
                        <div>
                            <div className="text-2xl">
                                Monitor Lobbying by{' '}
                                <span className="text-liq-ultramarine hover:underline">
                                    <Link to="/subjects">Subject</Link>
                                </span>
                            </div>
                            <div className="text-md mt-8 font-light">
                                All federal lobby registry data collated into 53 subject dashboards
                                with key real-time metrics on lobbying by all corporations and
                                organizations within a sector. Text-analytics reveal the policy
                                issues driving new registrations and lobbying communications in each
                                sector.
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-span-6 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between min-h-[20rem]">
                        <div>
                            <div className="text-2xl">
                                <span className="text-liq-orange hover:underline">
                                    <Link to="/issues">Custom Issue</Link>
                                </span>{' '}
                                Dashboards
                            </div>
                            <div className="text-md mt-8 font-light">
                                You define your issues as sets of keywords and we track all mentions
                                of these keywords in government texts. We also track all lobby
                                communications on these issues based on keywords in registration
                                texts
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-span-6 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between min-h-[20rem]">
                        <div>
                            <div className="text-2xl">Keyword Alerts</div>
                            <div className="text-md mt-8 font-light">
                                We send email alerts for mentions of critical keywords for
                                Reputation Risk and Crisis Management.
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-span-6 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between min-h-[20rem]">
                        <div>
                            <div className="text-2xl">Weekly Issue Emails</div>
                            <div className="text-md mt-8 font-light">
                                We send weekly email summaries of lobbying on your issues, as well
                                as full-sentence extracts of parliamentary discussions.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-start-7 col-span-6  flex justify-center items-center">
                    <img
                        src={process.env.PUBLIC_URL + '/Subject_Dashboard.png'}
                        className="w-full bg-slate-50 border border-slate-100 p-6 rounded-xl shadow-md	"
                        height="auto"
                        alt="logo"
                    />
                </div>
            </div>

            <div className="col-12 md:col-7 lg:col-6 py-14 lg:gap-12 gap-y-8 lg:gap-y-0"></div>
        </div>
    );
};

export default IssueTrackFeatures;
