import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../../store/store';
import { entityList, entityListExtra } from '../../../interfaces/generic-entity.interface';
import { percentage_change_num } from '../../../helpers/percentage_change';

interface SectorListState {
    sector_list: entityListExtra[] | null;
    sector_list_loading: boolean;

    org_list: entityListExtra[] | null;
    org_list_loading: boolean;

    inactive_org_list: entityListExtra[] | null;
    inactive_org_list_loading: boolean;
}

const initialState: SectorListState = {
    sector_list: null,
    sector_list_loading: false,

    org_list: null,
    org_list_loading: false,

    inactive_org_list: null,
    inactive_org_list_loading: false,
};

const baseURL = process.env.REACT_APP_API_BASE_URL;

export const fetchSectorList = createAsyncThunk(
    'sector/fetchList',
    async (): Promise<entityListExtra[]> => {
        const response = await axios.get(`${baseURL}/sector/list`);
        const data: entityList[] = response.data.sector;
        const converted_data: entityListExtra[] = data.map((entry) => {
            return {
                ...entry,
                // Calculate with potential nulls replaced by 0
                change_abs: (entry.last_month || 0) - (entry.two_months_ago || 0),
                change_rel: percentage_change_num(entry.two_months_ago || 0, entry.last_month || 0),
                available: true,
            };
        });
        return converted_data;
    }
);

export const fetchOrganizationList = createAsyncThunk(
    'sector/fetchOrgList',
    async ({ code }: { code: string | undefined }): Promise<entityListExtra[]> => {
        const response = await axios.get(`${baseURL}/org/${code}/list`);
        const data: entityList[] = response.data.organizations;
        const converted_data: entityListExtra[] = data
            .filter((entry) => entry.ttm > 0)
            .map((entry) => {
                return {
                    ...entry,
                    // Calculate with potential nulls replaced by 0
                    change_abs: (entry.last_month || 0) - (entry.two_months_ago || 0),
                    change_rel: percentage_change_num(
                        entry.two_months_ago || 0,
                        entry.last_month || 0
                    ),
                    available: true,
                };
            });
        return converted_data;
    }
);

export const fetchInactiveOrganizationList = createAsyncThunk(
    'sector/fetchInactiveOrgList',
    async ({ code }: { code: string | undefined }): Promise<entityListExtra[]> => {
        const response = await axios.get(`${baseURL}/org/${code}/list?inactive=true`);
        const data: entityListExtra[] = response.data.organizations;
        return data;
    }
);

const sectorListSlice = createSlice({
    name: 'sectorListReducer',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSectorList.pending, (state) => {
                state.sector_list_loading = true;
            })
            .addCase(fetchSectorList.fulfilled, (state, action) => {
                state.sector_list_loading = false;
                state.sector_list = action.payload;
            })
            .addCase(fetchSectorList.rejected, (state) => {
                state.sector_list_loading = false;
                state.sector_list = null;
            })
            .addCase(fetchOrganizationList.pending, (state) => {
                state.org_list_loading = true;
            })
            .addCase(fetchOrganizationList.fulfilled, (state, action) => {
                state.org_list_loading = false;
                state.org_list = action.payload;
            })
            .addCase(fetchOrganizationList.rejected, (state) => {
                state.org_list_loading = false;
                state.org_list = null;
            })
            .addCase(fetchInactiveOrganizationList.pending, (state) => {
                state.inactive_org_list_loading = true;
            })
            .addCase(fetchInactiveOrganizationList.fulfilled, (state, action) => {
                state.inactive_org_list_loading = false;
                state.inactive_org_list = action.payload;
            })
            .addCase(fetchInactiveOrganizationList.rejected, (state) => {
                state.inactive_org_list_loading = false;
                state.inactive_org_list = null;
            });
    },
});

export const selectSectorList = (state: RootState) => state.sectorList.sector_list;
export const selectSectorListLoading = (state: RootState) => state.sectorList.sector_list_loading;

export const selectOrganizationList = (state: RootState) => state.sectorList.org_list;
export const selectOrganizationListLoading = (state: RootState) =>
    state.sectorList.org_list_loading;

export const selectInactiveOrganizationList = (state: RootState) =>
    state.sectorList.inactive_org_list;
export const selectInactiveOrganizationListLoading = (state: RootState) =>
    state.sectorList.inactive_org_list_loading;

export default sectorListSlice.reducer;
