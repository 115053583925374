import React from 'react';
import BumperFlowList from './BumperFlowList';
import { bumperSuperList } from '../../../interfaces/bumper-types.interface';

// Title is an optional parameters with default specified in function signature below.
interface bumperProps {
    lists: bumperSuperList[];
    hiddenKeys?: string[];
    expandPrompt?: string;
}

const Bumper = ({ lists, hiddenKeys, expandPrompt }: bumperProps) => {
    return (
        <div className="flex flex-col gap-2">
            {lists.length > 0 ? (
                lists.map((entry: bumperSuperList, i: number) => {
                    return (
                        <BumperFlowList
                            key={i}
                            data={entry}
                            hiddenKeys={hiddenKeys}
                            expandPrompt={expandPrompt}
                        />
                    );
                })
            ) : (
                <span className="lg:text-sm text-xs font-medium text-slate-400 italic">
                    No data available
                </span>
            )}
        </div>
    );
};

export default Bumper;
