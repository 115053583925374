import { Link } from 'react-router-dom';
import MetaTag from '../../components/metatag-component/MetaTag';

const StakeholderFeatures = () => {
    return (
        <div className="xl:mx-24 mx-8 ">
            <MetaTag
                title="Stakeholder Dashboards"
                keywords="organization,sector,lobbying,emails,institutions,committees,senator dashboards,weekly emails,
                Lobbying Data, Real-Time Metrics, Federal Lobby Registry, Policy Issues, Text Analytics, Lobbying Communications, Organization Dashboards, Sector Dashboards, Weekly Lobby Monitor, Monthly Lobby Roundup, Public Office Holders, Government Institutions, Committee Meetings, Parliamentarians, MPs, Senators"
                description="Monitor lobbying by sector with real-time metrics and text analytics. Track organization and competitor lobbying, coordinate messaging, and stay informed with weekly and monthly lobby monitor emails. Access dashboards for institutions, DPOHs, committees, and MPs to understand lobbying activities, key issues, and legislative drivers."
            />

            <div className="col-12 md:col-7 lg:col-6 py-14 lg:gap-12 gap-y-8 lg:gap-y-0"></div>

            <div className="lg:grid grid-cols-12 grid-rows-auto gap-8 py-8 flex flex-col ">
                <div className="col-start-1 row-start-1 col-span-12 col-end-12 text-left">
                    <h1 className="lg:text-4xl text-3xl ">
                        {' '}
                        Navigating Sectors, Organizations, and Institutions
                    </h1>
                </div>

                <div className="lg:col-start-1 row-start-2 col-start-2 col-end-7 grid grid-cols-12 gap-6">
                    <div className="lg:col-span-6 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between max-h-[20rem]">
                        <div className="	">
                            <div className="text-2xl">
                                Monitor Lobbying by{' '}
                                <span className="text-liq-ultramarine hover:underline">
                                    <Link to="/sectors">Sector</Link>
                                </span>
                            </div>
                            <div className="text-md mt-8 font-light line-clamp-6">
                                All federal lobby registry data collated into sector dashboards with
                                key real-time metrics on lobbying by all corporations and
                                organizations within a sector. Text-analytics reveal the policy
                                issues driving new registrations and lobbying communications in each
                                sector.
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-span-6 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between ">
                        <div>
                            <div className="text-2xl">
                                Track{' '}
                                <span className="text-liq-lavender hover:underline">
                                    <Link to="/sectors">Organization</Link>
                                </span>{' '}
                                Lobbying
                            </div>
                            <div className="text-md mt-8 font-light line-clamp-6">
                                Need to coordinate messaging across multiple organizations or
                                up-to-date information on competitors? Our organization dashboards,
                                organized by sector, provide real-time statistics on lobbying, new
                                registrations and de-registrations
                            </div>
                        </div>
                    </div>

                    <div className="lg:col-span-6 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between min-h-[20rem]">
                        <div>
                            <div className="text-2xl">
                                <span className="text-liq-lavender hover:underline">
                                    <a href="https://liqtranscripts.blob.core.windows.net/summary/LobbyIQSampleWeeklyEmail.pdf">
                                        Weekly Lobby Monitor Emails
                                    </a>
                                </span>
                            </div>
                            <div className="text-md mt-8 font-light">
                                Receive a weekly list of all lobby communications and registrations
                                in your core areas of focus: by sector, organization, institution,
                                public office holders
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-span-6 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between min-h-[20rem]">
                        <div>
                            <div className="text-2xl">
                                <span className="text-liq-teal hover:underline">
                                    <a href="https://liqtranscripts.blob.core.windows.net/newsletter/LobbyIQ_Sector_CropProduction.pdf">
                                        Monthly Roundup Monitor Emails
                                    </a>
                                </span>
                            </div>
                            <div className="text-md mt-8 font-light">
                                Receive a monthly summary overview of lobby communications and
                                registrations in your core areas of focus: by sector, or institution
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-start-7 col-span-6  flex justify-center items-center">
                    <img
                        src={process.env.PUBLIC_URL + '/sector_dashboard.png'}
                        className="w-full bg-slate-50 border border-slate-100 p-6 rounded-xl shadow-md	"
                        height="auto"
                        alt="Sector Dashboard"
                    />
                </div>
            </div>

            <div className="grid grid-cols-12 py-14 lg:gap-12 gap-y-8 lg:gap-y-0">
                <div className="col-start-1 lg:col-end-7  col-end-13">
                    <div className="lg:h-8 text-slate-600 text-lg">
                        Our Platform and Features Explained
                    </div>
                    <hr className="h-0.5 my-3 bg-slate-100 rounded" />
                    <iframe
                        className="w-full h-5/6 rounded-md shadow-md"
                        src="https://www.youtube.com/embed/mQ7tGS6OxLQ?si=TImffCxr1xA5Enkr"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                    />
                </div>
                <div className="lg:col-start-7 col-start-1 lg:col-span-6 col-span-12 flex justify-center items-center">
                    <img
                        src={process.env.PUBLIC_URL + '/Institutions_Dashboard.png'}
                        className="w-full bg-slate-50 border border-slate-100 p-6 rounded-xl shadow-md	"
                        height="auto"
                        alt="Institutions Dashboard containing DPOH & Institutions "
                    />
                </div>
            </div>

            <div className="lg:grid grid-cols-12 grid-rows-auto gap-8 py-8 md:flex md:flex-col ">
                <div className="lg:col-start-1 row-start-1 col-start-2 col-end-7 grid grid-cols-12 gap-6">
                    <div className="lg:col-span-6 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between min-h-[20rem]">
                        <div>
                            <div className="text-2xl">
                                Monitor{' '}
                                <span className="text-liq-orange hover:underline">
                                    <Link to="/institutions">Institutions</Link>
                                </span>
                            </div>
                            <div className="text-md mt-8 font-light">
                                Institution dashboards provide real-time metrics on the lobbying of
                                each federal government institution and its DPOHs. Text-analytics
                                identify the legislative, policy, and regulatory issues that drive
                                lobbying activity into an institution
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-span-6 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between min-h-[20rem]">
                        <div>
                            <div className="text-2xl">
                                Monitor{' '}
                                <span className="text-liq-orange hover:underline">
                                    <Link to="/institutions">DPOHs</Link>
                                </span>
                            </div>
                            <div className="text-md mt-8 font-light">
                                DPOH dashboards provide real-time metrics on the lobbying of each
                                public office holder, who meets with them and on what issues.
                                Text-analytics identify the issues that drive lobbying activity to a
                                public office holder
                            </div>
                        </div>
                    </div>

                    <div className="lg:col-span-6 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between min-h-[20rem]">
                        <div>
                            <div className="text-2xl">
                                Monitor{' '}
                                <span className="text-liq-teal hover:underline">
                                    <Link to="/committees">Committees</Link>
                                </span>
                            </div>
                            <div className="text-md mt-8 font-light">
                                House of Commons and Senate committee dashboards track lobbying of
                                the MPs on each committee and the key policy issues being discussed
                                during committee meetings.
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-span-6 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between min-h-[20rem]">
                        <div>
                            <div className="text-2xl">
                                Monitor{' '}
                                <span className="text-liq-red hover:underline">
                                    <Link to="/committees">MPs</Link>
                                </span>
                            </div>
                            <div className="text-md mt-8 font-light">
                                MP and Senator dashboards track lobbying and what each
                                parliamentarian's key issues are.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-start-7 col-span-6  flex justify-center items-center">
                    <img
                        src={process.env.PUBLIC_URL + '/PDF_Generated_Dasboard.png'}
                        className="w-full bg-slate-50 border border-slate-100 p-6 rounded-xl shadow-md	"
                        height="auto"
                        alt="Committees Dashboard containing MP Lists"
                    />
                </div>
            </div>

            <div className="lg:grid grid-cols-12 grid-rows-auto gap-8 py-8 md:flex md:flex-col "></div>

            <div className="col-12 md:col-7 lg:col-6 py-14 lg:gap-12 gap-y-8 lg:gap-y-0"></div>
        </div>
    );
};

export default StakeholderFeatures;
