import axios from 'axios';
import {
    entityLobbyingActivity,
    entityOrg,
    entityOverview,
    entityOverviewRefined,
    entityOverviews,
    keywordMonthRaw,
} from '../../../interfaces/generic-entity.interface';
import { overviewFormatter } from '../../../helpers/overview_formatter';
import moment from 'moment';
import {
    entityActivity,
    entityActivityRaw,
} from '../../../interfaces/organization-entity.interface';
import {
    consultantLinkFormatter,
    lobbyFirmLinkFormatter,
    organizationLinkFormatter,
    sectorLinkFormatter,
} from '../../../helpers/internal_routing';
import { committeeMeetingLinkFormatter } from '../../../helpers/external_routing';
import { bumperSuperList } from '../../../interfaces/bumper-types.interface';
import { mpBiography } from '../interface/dpoh-entity.interface';

// Base URL for accessing the DPOH
const baseURL = process.env.REACT_APP_API_BASE_URL;

// Fetch an overview of the given DPOH
const fetchDPOHOverview = async (
    inst: string,
    dpoh: string,
    date: string,
    includeHOC: boolean,
    includeMinisterPosition: boolean
) => {
    const year = date?.split('-')[0];
    const month = date?.split('-')[1];

    const yearly_response = await axios.get(
        `${baseURL}/dpoh/${encodeURIComponent(inst)}/${encodeURIComponent(
            dpoh
        )}/overview?monthly=false&includeHOC=${includeHOC}&includeMinisterPosition=${includeMinisterPosition}`
    );
    const yearly_data: entityOverviewRefined[] = yearly_response.data.overview
        .filter((entry: any) => +entry.year <= +year)
        .map((entry: entityOverview) => {
            return {
                ...entry,
                'Lobbying Reports': entry.count || 0,
                'by Consultants': entry.external || 0,
                Organizations: entry.organizations || 0,
                Sectors: entry.sectors || 0,
            };
        });

    const inverted_yearly = overviewFormatter(yearly_data, [
        'Lobbying Reports',
        'by Consultants',
        'Organizations',
        'Sectors',
    ]);

    const monthly_response = await axios.get(
        `${baseURL}/dpoh/${encodeURIComponent(inst)}/${encodeURIComponent(
            dpoh
        )}/overview?monthly=true&includeHOC=${includeHOC}&includeMinisterPosition=${includeMinisterPosition}`
    );

    const monthly_data: entityOverview[] = monthly_response.data.overview
        .filter((entry: any) => {
            return (
                +[entry.year, (entry.month + '').padStart(2, '0')].join('') <=
                +[year, month].join('')
            );
        })
        .slice(0, 18);

    const overview: entityOverviews = {
        yearly: inverted_yearly,
        monthly: monthly_data,
    };

    return overview;
};

// Fetch MP bio
const fetchMPBio = async (mpFullName: string, date: string | undefined): Promise<mpBiography> => {
    const nameSplit: string[] = mpFullName.split(' ');
    const first = nameSplit[0];
    const last = nameSplit[nameSplit.length - 1];

    const bio_response = await axios.get(
        `${baseURL}/dpoh/103/${mpFullName}/mp/bio?first=${first}&last=${last}&date=${date}`
    );
    const bio_data: mpBiography = bio_response.data;

    return bio_data;
};

// Fetch HANS meeting sentences from the given MP
const fetchMPHansSentences = async (
    inst: string,
    mpFullName: string,
    date: string | undefined
): Promise<bumperSuperList[]> => {
    const nameSplit: string[] = mpFullName.split(' ');
    const first = nameSplit[0];
    const last = nameSplit[nameSplit.length - 1];

    const sentence_response = await axios.get(
        `${baseURL}/dpoh/${inst}/${mpFullName}/mp/sentences?first=${first}&last=${last}&date=${date}`
    );
    const sentence_data: any[] = sentence_response.data;

    const sentence_list: any[] = sentence_data.map((entry: keywordMonthRaw) => {
        return {
            ...entry,
            title:
                entry.meetingNo &&
                entry.parliament &&
                entry.session &&
                entry.committee &&
                entry.committee !== 'HANS'
                    ? committeeMeetingLinkFormatter(
                          entry.committee,
                          entry.parliament,
                          entry.session,
                          entry.meetingNo,
                          entry.title
                              ? `${entry.committee} (Meeting ${entry.meetingNo} - ${entry.date}): ${entry.title} `
                              : `${entry.committee}: ${entry.date} - Meeting ${entry.meetingNo}`
                      )
                    : entry.title
                    ? `${entry.committee}: ${entry.title} (Meeting ${entry.meetingNo} - ${entry.date})`
                    : `${entry.committee}: ${entry.date} - Meeting ${entry.meetingNo}`,
            lists: entry.datasets,
        };
    });

    return sentence_list;
};

// Fetch recent lobbying communications to a given DPOH
const fetchDPOHRecentLobbyingActivity = async (
    inst: string,
    dpoh: string,
    date: string,
    includeHOC: boolean,
    includeMinisterPosition: boolean
) => {
    const response = await axios.get(
        `${baseURL}/dpoh/${encodeURIComponent(inst)}/${encodeURIComponent(
            dpoh
        )}/activity?limit=25&date=${date}&includeHOC=${includeHOC}&includeMinisterPosition=${includeMinisterPosition}`
    );
    const data: entityLobbyingActivity[] = response.data.dpohActivity;
    const converted_data: entityLobbyingActivity[] = data.map((entry) => {
        return {
            ...entry,
            'Prior Comms (36M)': entry.three_years_prior,
            date: moment.utc(entry.date).format('YYYY-MM-DD'),
            organization:
                entry.sector_id && entry.sector
                    ? organizationLinkFormatter(entry.sector_id, entry.sector, entry.client)
                    : entry.client,
            sector:
                entry.sector_id && entry.sector
                    ? sectorLinkFormatter(entry.sector_id, entry.sector)
                    : entry.sector,
            lobby_firm:
                entry.lobby_firm === null || entry.consultant === null
                    ? 'In-House'
                    : lobbyFirmLinkFormatter(entry.lobby_firm),
            consultant:
                entry.lobby_firm === null || entry.consultant === null
                    ? 'In-House'
                    : consultantLinkFormatter(entry.lobby_firm, entry.consultant),
            link: `https://lobbycanada.gc.ca/app/secure/ocl/lrs/do/cmmLgPblcVw?comlogId=${entry.comm}`,
        };
    });
    return converted_data;
};

// Fetch recent lobbying communications to a given DPOH
const fetchDPOHOrgActivity = async (
    inst: string,
    dpoh: string,
    date: string,
    includeHOC: boolean,
    includeMinisterPosition: boolean
) => {
    const response = await axios.get(
        `${baseURL}/dpoh/${encodeURIComponent(inst)}/${encodeURIComponent(
            dpoh
        )}/topportionsorg?limit=25&date=${date}&includeHOC=${includeHOC}&includeMinisterPosition=${includeMinisterPosition}`
    );
    const data: entityActivityRaw[] = response.data.dpohTopPortionsOrg;
    const converted_data: entityActivity[] = data.map((entry) => {
        return {
            ...entry,
            name:
                entry.sector_id && entry.sector
                    ? organizationLinkFormatter(entry.sector_id, entry.sector, entry.name)
                    : entry.name,
            sector:
                entry.sector_id && entry.sector
                    ? sectorLinkFormatter(entry.sector_id, entry.sector)
                    : entry.sector,
            'prior_comms_(12M)': entry.one_year_count,
            'prior_comms_(36M)': entry.three_year_count,
            'percent_comms_(12M)':
                Math.round((entry.one_year_count / entry.one_year_total) * 100 * 100) / 100,
        };
    });
    return converted_data;
};

// Fetch top sectors for a given dpoh
const fetchDPOHTopSectors = async (
    inst: string,
    dpoh: string,
    date: string,
    includeHOC: boolean,
    includeMinisterPosition: boolean
) => {
    const response = await axios.get(
        `${baseURL}/dpoh/${encodeURIComponent(
            inst
        )}/${dpoh}/topsectors?date=${date}&includeHOC=${includeHOC}&includeMinisterPosition=${includeMinisterPosition}`
    );

    const data: entityOrg[] = response.data.dpohTopSectors;
    // Count the total number of comms for sectors outside of the top 6
    var other_count = 0;
    if (data.length > 6)
        other_count = data
            .slice(6)
            .map((item) => item.count)
            .reduce((sum, next) => sum + next);

    const top_six = data.slice(0, 6);
    if (other_count > 0) top_six.push({ name: 'Other', code: 'Other', count: other_count });

    return top_six;
};

// Fetch top sectors for a given dpoh
const fetchDPOHInternalExternalSplit = async (
    inst: string,
    dpoh: string,
    date: string,
    includeHOC: boolean,
    includeMinisterPosition: boolean
) => {
    const response = await axios.get(
        `${baseURL}/dpoh/${encodeURIComponent(
            inst
        )}/${dpoh}/firmbreakdown?date=${date}&includeHOC=${includeHOC}&includeMinisterPosition=${includeMinisterPosition}`
    );

    const data: entityOrg[] = response.data.dpohFirmBreakdown;
    // Count the total number of comms for sectors outside of the top 6
    var other_count = 0;
    if (data.length > 6)
        other_count = data
            .slice(6)
            .map((item) => item.count)
            .reduce((sum, next) => sum + next);

    const top_six = data.slice(0, 6);
    if (other_count > 0) top_six.push({ name: 'Other', code: 'Other', count: other_count });

    return top_six;
};

// Fetch top subjects for a given dpoh
const fetchDPOHTopSubjects = async (
    inst: string,
    dpoh: string,
    date: string,
    includeHOC: boolean,
    includeMinisterPosition: boolean
) => {
    const response = await axios.get(
        `${baseURL}/dpoh/${encodeURIComponent(
            inst
        )}/${dpoh}/topsubjects?date=${date}&includeHOC=${includeHOC}&includeMinisterPosition=${includeMinisterPosition}`
    );

    const data: entityOrg[] = response.data.dpohTopSubjects;
    // Count the total number of comms for sectors outside of the top 6
    var other_count = 0;
    if (data.length > 6)
        other_count = data
            .slice(6)
            .map((item) => item.count)
            .reduce((sum, next) => sum + next);

    const top_six = data.slice(0, 6);
    if (other_count > 0) top_six.push({ name: 'Other', code: 'Other', count: other_count });

    return top_six;
};

export const DPOHAPI = {
    fetchDPOHOverview,
    fetchMPBio,
    fetchMPHansSentences,
    fetchDPOHTopSectors,
    fetchDPOHInternalExternalSplit,
    fetchDPOHTopSubjects,
    fetchDPOHRecentLobbyingActivity,
    fetchDPOHOrgActivity,
};
